import { CareerStage, CareerStageExpanded } from "@common/constants";

export type ClientFormInput = {
  EMAIL: string;
  FIRST_NAME: string;
  LAST_NAME: string;
  ADDRESS_STREET: string;
  ADDRESS_STREET_2: string;
  ADDRESS_CITY: string;
  ADDRESS_STATE: string;
  ADDRESS_ZIP: string;
  PHONE_NUMBER: string;
  SSN: string;
  DOB: string;
  MAILING_ADDRESS_STREET: string;
  MAILING_ADDRESS_STREET_2: string;
  MAILING_ADDRESS_CITY: string;
  MAILING_ADDRESS_STATE: string;
  MAILING_ADDRESS_ZIP: string;
  OCCUPATION: string;
  EMPLOYMENT_STATUS: string;
  EMPLOYER_NAME: string;
  DRIVER_LICENSE_NUMBER: string;
  DRIVER_LICENSE_STATE: string;
  DRIVER_LICENSE_EXP: string;
  HAS_FINRA_ASSOCIATION: boolean;
  PROFILE: ClientProfile;
};

export type ClientProfile = {
  npiNumber: string;
  specialty: string;
  careerStage: CareerStage;
};

export type HouseholdFormInputs = {
  primaryClient: ClientFormInput;
  secondaryClient: ClientFormInput;
};

export type ClientBackendInput = {
  email: string;
  firstName: string;
  lastName: string;
  addressStreet: string;
  addressStreetLineTwo: string;
  addressCity: string;
  addressState: string;
  addressZip: string;
  profile: ClientProfile;
};

export type HouseholdFormInputsBackend = {
  primaryClient?: Omit<ClientBackendInput, "profile"> & {
    profile?: ClientProfile;
  };
  secondaryClient?: Omit<ClientBackendInput, "profile"> & {
    profile?: ClientProfile;
  };
};

export type OptionType = {
  name: string;
  id: string | number | null;
} | null;

export enum ClientInfoTabId {
  All = "all",
  Profile = "profile",
  DisabilityInsurance = "disability-insurance",
  TermLifeInsurance = "term-life-insurance",
  RiskTolerance = "risk-tolerance",
}

export type FieldValue = number | string | boolean | null | undefined;

export interface ClientInfoComputedFields {
  RTQ_TARGET_PORTFOLIO_TYPE: string | undefined;
}

export interface BaseClientInfo {
  ADDRESS_CITY: string;
  ADDRESS_STATE: string;
  ADDRESS_STREET: string;
  ADDRESS_STREET_2: string;
  ADDRESS_ZIP: string;
  ANNUAL_INCOME: number;
  CAREER_STAGE: CareerStage;
  CAREER_STAGE_EXPANDED: CareerStageExpanded;
  CLIENT_ID: string;
  DOB: string;
  DOCUSIGN_WDA_ENVELOPE_ID: string;
  DOCUSIGN_WMA_ENVELOPE_ID: string;
  DRIVER_LICENSE_EXP: string;
  DRIVER_LICENSE_NUMBER: string;
  DRIVER_LICENSE_STATE: string;
  EMAIL: string;
  EMPLOYER_NAME: string;
  EMPLOYER_ADDRESS_STREET: string;
  EMPLOYER_ADDRESS_STREET_2: string;
  EMPLOYER_ADDRESS_CITY: string;
  EMPLOYER_ADDRESS_STATE: string;
  EMPLOYER_ADDRESS_ZIP: string;
  EMPLOYMENT_STATUS: string;
  FIRST_NAME: string;
  GENDER: string;
  HAS_FINRA_ASSOCIATION: boolean;
  HOUSEHOLD_ID: string;
  IS_PRIMARY_CLIENT: boolean;
  LAST_NAME: string;
  MAILING_ADDRESS_CITY: string;
  MAILING_ADDRESS_STATE: string;
  MAILING_ADDRESS_STREET: string;
  MAILING_ADDRESS_STREET_2: string;
  MAILING_ADDRESS_ZIP: string;
  SOURCE_INCOME: string;
  TOTAL_ASSETS: number;
  NPI_NUMBER: string;
  OCCUPATION: string;
  PHONE_NUMBER: string;
  SPECIALTY: string;
  SSN: string;
  SSN_LAST_FOUR_DIGITS: string;
  TOBACCO_USE: boolean;
  YEARS_PRACTICE: number;
  RTQ_EXPECTED_MONEY_NEED_PERIOD: string;
  RTQ_INCOME_PROJECTION: string;
  RTQ_INCOME_PROTECTION_STATUS: string;
  RTQ_INVESTMENT_KNOWLEDGE: string;
  RTQ_INVESTMENT_GOAL: string;
  RTQ_COVID_19_INVESTMENT_STRATEGY: string;
  RTQ_INVESTMENT_CHECK_FREQUENCY: string;
  RTQ_INVESTMENT_PORTFOLIO_CHOICE: string;
  RTQ_FINANCIAL_EMERGENCY_ABILITY: string;
  RTQ_SCORE: number;
  BIRTH_STATE: string;
  BORN_IN_THE_USA: boolean;
  NET_WORTH: number;
  CURR_LIFE_POLICY_TYPE: string;
  CURR_LIFE_POLICY_MONTHLY_PREM: number;
  ABOUT_US_REFERRAL: string;
  INTERESTS: string;
  EULA_ACCEPT_TIMESTAMP: string;
  PLACE_OF_BIRTH: string;
}

export type ClientInfo = BaseClientInfo & ClientInfoComputedFields;
