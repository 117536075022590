import {
  BaseClientInfo,
  ClientInfo,
  ClientInfoComputedFields,
  FieldValue,
} from "./types";
import _ from "lodash";
import moment from "moment";
import { EMPTY_FIELD_PLACEHOLDER } from "./constants";
import {
  CareerStage,
  CareerStageExpanded,
  CareerStageMapping,
} from "@common/constants";
import { getPortfolioTypeFromRiskScore } from "@earned/wizard";

const isEmpty = (v: FieldValue) => v === null || v === undefined || v === "";
const transformUpperSnakeCase = (v: string) =>
  v
    .split("_")
    .map((substr) => _.capitalize(substr.toLowerCase()))
    .join(" ");

const formatters = {
  string: (v: FieldValue) => (isEmpty(v) ? EMPTY_FIELD_PLACEHOLDER : String(v)),
  transform:
    (
      config: { rule: (v: string) => string } = {
        rule: transformUpperSnakeCase,
      }
    ) =>
    (v: FieldValue) =>
      isEmpty(v) ? EMPTY_FIELD_PLACEHOLDER : config.rule(v as string),
  boolean:
    (
      config: { trueLabel: string; falseLabel: string } = {
        trueLabel: "Yes",
        falseLabel: "No",
      }
    ) =>
    (v: FieldValue) =>
      isEmpty(v)
        ? EMPTY_FIELD_PLACEHOLDER
        : (v as boolean)
        ? config.trueLabel
        : config.falseLabel,
  currency:
    (config: { includeCents?: boolean } = { includeCents: false }) =>
    (v: FieldValue) => {
      if (isEmpty(v)) return EMPTY_FIELD_PLACEHOLDER;
      return (v as number).toLocaleString("en-us", {
        currency: "USD",
        style: "currency",
        maximumFractionDigits: config.includeCents ? 2 : 0,
      });
    },
  capitalize: (v: FieldValue) =>
    isEmpty(v) ? EMPTY_FIELD_PLACEHOLDER : _.capitalize(v as string),
  date:
    (config: { mask: string } = { mask: "MM/DD/yyyy" }) =>
    (v: FieldValue) =>
      isEmpty(v) || !moment(v as string).isValid()
        ? EMPTY_FIELD_PLACEHOLDER
        : moment(v as string).format(config.mask),
  ssn:
    (config: { hidden: boolean } = { hidden: true }) =>
    (v: FieldValue) => {
      if (isEmpty(v)) return EMPTY_FIELD_PLACEHOLDER;
      const withHyphens = (v as string)
        .split("")
        .map((c, i) => c + ([2, 4].includes(i) ? "-" : ""))
        .join("");
      if (config.hidden)
        return withHyphens
          .split("")
          .map((c, i) => (c === "-" ? c : i < withHyphens.length - 4 ? "X" : c))
          .join("");
      return withHyphens;
    },
  careerStage: (v: FieldValue) => {
    if (isEmpty(v)) return EMPTY_FIELD_PLACEHOLDER;
    return CareerStageMapping[v as CareerStage | CareerStageExpanded];
  },
  phone: (v: FieldValue) => {
    if (isEmpty(v)) return EMPTY_FIELD_PLACEHOLDER;
    const cleaned = ("" + v).replace(/\D/g, "");
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return String(v);
  },
};

const fieldMappings: Record<
  keyof ClientInfo,
  {
    label: string;
    formatter: (v: FieldValue) => string;
    clipboardFormatter?: (v: FieldValue) => string;
  }
> = {
  ADDRESS_CITY: { label: "City", formatter: formatters.string },
  ADDRESS_STATE: { label: "State", formatter: formatters.string },
  ADDRESS_STREET: { label: "Street", formatter: formatters.string },
  ADDRESS_STREET_2: { label: "Unit", formatter: formatters.string },
  ADDRESS_ZIP: { label: "Zip Code", formatter: formatters.string },
  ANNUAL_INCOME: { label: "Annual Income", formatter: formatters.currency() },
  CAREER_STAGE: { label: "Career Stage", formatter: formatters.careerStage },
  CAREER_STAGE_EXPANDED: {
    label: "Career Stage (Expanded)",
    formatter: formatters.careerStage,
  },
  CLIENT_ID: { label: "Client ID", formatter: formatters.string },
  DOB: { label: "Date of Birth", formatter: formatters.date() },
  DOCUSIGN_WDA_ENVELOPE_ID: {
    label: "Docusign WDA Envelope ID",
    formatter: formatters.string,
  },
  DOCUSIGN_WMA_ENVELOPE_ID: {
    label: "Docusign WMA Envelope ID",
    formatter: formatters.string,
  },
  DRIVER_LICENSE_EXP: {
    label: "Driver's License Expiration Date",
    formatter: formatters.date(),
  },
  DRIVER_LICENSE_NUMBER: {
    label: "Driver's License Number",
    formatter: formatters.string,
  },
  DRIVER_LICENSE_STATE: {
    label: "Driver's License State",
    formatter: formatters.string,
  },
  EMAIL: { label: "Email", formatter: formatters.string },
  EMPLOYER_NAME: { label: "Employer", formatter: formatters.string },
  EMPLOYER_ADDRESS_STREET: {
    label: "Employer Address Street",
    formatter: formatters.string,
  },
  EMPLOYER_ADDRESS_STREET_2: {
    label: "Employer Address Unit",
    formatter: formatters.string,
  },
  EMPLOYER_ADDRESS_CITY: {
    label: "Employer Address City",
    formatter: formatters.string,
  },
  EMPLOYER_ADDRESS_STATE: {
    label: "Employer Address State",
    formatter: formatters.string,
  },
  EMPLOYER_ADDRESS_ZIP: {
    label: "Employer Address Zip Code",
    formatter: formatters.string,
  },
  EMPLOYMENT_STATUS: {
    label: "Employment Status",
    formatter: formatters.string,
  },
  FIRST_NAME: { label: "First Name", formatter: formatters.string },
  GENDER: { label: "Gender", formatter: formatters.capitalize },
  HAS_FINRA_ASSOCIATION: {
    label: "Has FINRA Association",
    formatter: formatters.boolean(),
  },
  HOUSEHOLD_ID: { label: "Household ID", formatter: formatters.string },
  IS_PRIMARY_CLIENT: {
    label: "Primary Client",
    formatter: formatters.boolean(),
  },
  LAST_NAME: { label: "Last Name", formatter: formatters.string },
  MAILING_ADDRESS_CITY: {
    label: "Mailing Address City",
    formatter: formatters.string,
  },
  MAILING_ADDRESS_STATE: {
    label: "Mailing Address State",
    formatter: formatters.string,
  },
  MAILING_ADDRESS_STREET: {
    label: "Mailing Address Street",
    formatter: formatters.string,
  },
  MAILING_ADDRESS_STREET_2: {
    label: "Mailing Address Unit",
    formatter: formatters.string,
  },
  MAILING_ADDRESS_ZIP: {
    label: "Mailing Address Zip Code",
    formatter: formatters.string,
  },
  NPI_NUMBER: { label: "NPI Number", formatter: formatters.string },
  OCCUPATION: { label: "Occupation", formatter: formatters.string },
  SOURCE_INCOME: { label: "Source of Income", formatter: formatters.string },
  TOTAL_ASSETS: { label: "Total Assets", formatter: formatters.currency() },
  PHONE_NUMBER: { label: "Phone", formatter: formatters.phone },
  SPECIALTY: { label: "Specialty", formatter: formatters.string },
  SSN: {
    label: "Social Security #",
    formatter: formatters.ssn(),
    clipboardFormatter: formatters.ssn({ hidden: false }),
  },
  SSN_LAST_FOUR_DIGITS: {
    label: "Social Security # (Last Four)",
    formatter: formatters.string,
  },
  TOBACCO_USE: { label: "Uses Tobacco", formatter: formatters.boolean() },
  YEARS_PRACTICE: { label: "Years Practicing", formatter: formatters.string },
  RTQ_EXPECTED_MONEY_NEED_PERIOD: {
    label: "Expected Money Need Period",
    formatter: formatters.transform(),
  },
  RTQ_COVID_19_INVESTMENT_STRATEGY: {
    label: "COVID-19 Investment Strategy",
    formatter: formatters.transform(),
  },
  RTQ_INCOME_PROJECTION: {
    label: "Income Projection",
    formatter: formatters.transform(),
  },
  RTQ_INCOME_PROTECTION_STATUS: {
    label: "Income Projection Status",
    formatter: formatters.transform(),
  },
  RTQ_INVESTMENT_CHECK_FREQUENCY: {
    label: "Investment Check Frequency",
    formatter: formatters.transform(),
  },
  RTQ_INVESTMENT_GOAL: {
    label: "Investment Goal",
    formatter: formatters.transform(),
  },
  RTQ_INVESTMENT_KNOWLEDGE: {
    label: "Investment Knowledge",
    formatter: formatters.transform(),
  },
  RTQ_INVESTMENT_PORTFOLIO_CHOICE: {
    label: "Portfolio Choice",
    formatter: formatters.transform(),
  },
  RTQ_FINANCIAL_EMERGENCY_ABILITY: {
    label: "Ability to Handle Financial Emergency",
    formatter: formatters.transform(),
  },
  RTQ_SCORE: { label: "Risk Tolerance Score", formatter: formatters.string },
  RTQ_TARGET_PORTFOLIO_TYPE: {
    label: "Target Portfolio Type",
    formatter: formatters.string,
  },
  BIRTH_STATE: { label: "Birth State", formatter: formatters.string },
  BORN_IN_THE_USA: {
    label: "Born in the USA",
    formatter: formatters.boolean(),
  },
  NET_WORTH: { label: "Net Worth", formatter: formatters.currency() },
  CURR_LIFE_POLICY_TYPE: {
    label: "Current Life Insurance Policy Type",
    formatter: formatters.transform(),
  },
  CURR_LIFE_POLICY_MONTHLY_PREM: {
    label: "Current Life Insurance Monthly Premium",
    formatter: formatters.currency(),
  },
  ABOUT_US_REFERRAL: {
    label: "About Us (Referral)",
    formatter: formatters.string,
  },
  INTERESTS: {
    label: "Financial Interests",
    formatter: formatters.string,
  },
  EULA_ACCEPT_TIMESTAMP: {
    label: "Accepted EULA Terms Timestamp",
    formatter: formatters.date({ mask: "LLL" }),
  },
  PLACE_OF_BIRTH: { label: "Place of Birth", formatter: formatters.string },
};

const computedFields: {
  [K in keyof ClientInfoComputedFields]: (
    clientInfo: BaseClientInfo
  ) => ClientInfoComputedFields[K];
} = {
  RTQ_TARGET_PORTFOLIO_TYPE: (clientInfo) => {
    if (typeof clientInfo.RTQ_SCORE === "number") {
      return getPortfolioTypeFromRiskScore(clientInfo.RTQ_SCORE);
    }
  },
};

export { formatters, fieldMappings, computedFields, isEmpty };
