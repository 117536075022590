import { SignupFieldValues, SignupStepNames } from "@earned/wizard";
import { StateCreator, create } from "zustand";
import { PersistOptions, persist } from "zustand/middleware";

interface SignupState {
  fields: SignupFieldValues;
  setField: <V>(field: { key: keyof SignupFieldValues; value: V }) => void;
  reset: () => void;
  navHistory: SignupStepNames[];
  setNavHistory: (history: SignupStepNames[]) => void;
}

type SignupPersist = (
  config: StateCreator<SignupState>,
  options: PersistOptions<SignupState>
) => StateCreator<SignupState>;

const isReactNative =
  typeof navigator !== "undefined" && navigator.product === "ReactNative";

let AsyncStorage: any;

if (isReactNative) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const asyncStorage = require("@react-native-async-storage/async-storage");
  AsyncStorage = asyncStorage.default;
}

const initialState = {
  fields: {} as SignupFieldValues,
  navHistory: [] as SignupStepNames[],
};
const useSignupStore = create<SignupState>(
  (persist as SignupPersist)(
    (set) => ({
      ...initialState,
      reset: () => set(initialState),
      setNavHistory: (history) => set({ navHistory: history }),
      setField: (field) =>
        set((state) => ({
          fields: {
            ...state.fields,
            [field.key]: field.value,
          },
        })),
    }),
    {
      name: "signup-wizard-state",
      version: 0.2,
      getStorage: () => (isReactNative ? AsyncStorage : localStorage),
    }
  )
);

export { useSignupStore };
