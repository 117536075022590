import { themeColors } from "@common/constants";

export enum SCENARIO {
  Recommended = "recommended",
  Current = "current",
}

export enum IMPORTANCE_GROUP {
  Need = "Need",
  Want = "Want",
  Wish = "Wish",
}

export enum CHARTS {
  PROJECTED_FINANCIAL_PLAN = "projected-financial-plan",
  GOAL_TIMELINE = "goal-timeline",
}

export enum Tabs {
  PlanOverview = "plan-overview",
  PlayZone = "play-zone",
  Fears = "what-are-you-afraid-of",
}

export const COLOR_CLASSES_BY_GOAL_TYPE = {
  needs: [
    "bg-green-500",
    "bg-green-400",
    "bg-green-300",
    "bg-green-200",
    "bg-green-100",
  ],
  wants: ["bg-red-500", "bg-red-400", "bg-red-300", "bg-red-200", "bg-red-100"],
  wishes: [
    "bg-blue-500",
    "bg-blue-400",
    "bg-blue-300",
    "bg-blue-200",
    "bg-blue-100",
  ],
};

export const FILLS_BY_IMPORTANCE_GROUP: Record<
  Forme.ImportanceGroup,
  string[]
> = {
  Need: [
    themeColors["green"][500],
    themeColors["green"][400],
    themeColors["green"][300],
    themeColors["green"][200],
    themeColors["green"][100],
  ],
  Want: [
    themeColors["red"][500],
    themeColors["red"][400],
    themeColors["red"][300],
    themeColors["red"][200],
    themeColors["red"][100],
  ],
  Wish: [
    themeColors["blue"][500],
    themeColors["blue"][400],
    themeColors["blue"][300],
    themeColors["blue"][200],
    themeColors["blue"][100],
  ],
};

export const IMPORTANCE_GROUP_BY_IMPORTANCE: Record<
  Forme.FinancialGoal["importance"],
  IMPORTANCE_GROUP
> = {
  10: IMPORTANCE_GROUP.Need,
  9: IMPORTANCE_GROUP.Need,
  8: IMPORTANCE_GROUP.Need,
  7: IMPORTANCE_GROUP.Want,
  6: IMPORTANCE_GROUP.Want,
  5: IMPORTANCE_GROUP.Want,
  4: IMPORTANCE_GROUP.Want,
  3: IMPORTANCE_GROUP.Wish,
  2: IMPORTANCE_GROUP.Wish,
  1: IMPORTANCE_GROUP.Wish,
};
