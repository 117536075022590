import { TabOption } from "@earned/core";
import { ClientInfo, ClientInfoTabId } from "./types";

const CLIENT_INFO_TAB_OPTIONS: TabOption<ClientInfoTabId>[] = [
  {
    id: ClientInfoTabId.All,
    label: "All",
  },
  {
    id: ClientInfoTabId.Profile,
    label: "Profile",
  },
  {
    id: ClientInfoTabId.DisabilityInsurance,
    label: "Disability Insurance",
  },
  {
    id: ClientInfoTabId.TermLifeInsurance,
    label: "Term Life Insurance",
  },
  {
    id: ClientInfoTabId.RiskTolerance,
    label: "Risk Tolerance",
  },
];

const ALL_CLIENT_INFO_FIELDS: (keyof ClientInfo)[] = [
  "FIRST_NAME",
  "LAST_NAME",
  "EMAIL",
  "PHONE_NUMBER",
  "ADDRESS_STREET",
  "ADDRESS_STREET_2",
  "ADDRESS_CITY",
  "ADDRESS_STATE",
  "ADDRESS_ZIP",
  "ANNUAL_INCOME",
  "CAREER_STAGE",
  "CAREER_STAGE_EXPANDED",
  "CLIENT_ID",
  "DOB",
  "DRIVER_LICENSE_EXP",
  "DRIVER_LICENSE_NUMBER",
  "DRIVER_LICENSE_STATE",
  "EMPLOYER_NAME",
  "EMPLOYER_ADDRESS_STREET",
  "EMPLOYER_ADDRESS_STREET_2",
  "EMPLOYER_ADDRESS_CITY",
  "EMPLOYER_ADDRESS_STATE",
  "EMPLOYER_ADDRESS_ZIP",
  "EMPLOYMENT_STATUS",
  "GENDER",
  "HAS_FINRA_ASSOCIATION",
  "HOUSEHOLD_ID",
  "IS_PRIMARY_CLIENT",
  "MAILING_ADDRESS_STREET",
  "MAILING_ADDRESS_STREET_2",
  "MAILING_ADDRESS_CITY",
  "MAILING_ADDRESS_STATE",
  "MAILING_ADDRESS_ZIP",
  "NPI_NUMBER",
  "OCCUPATION",
  "SOURCE_INCOME",
  "TOTAL_ASSETS",
  "SPECIALTY",
  "SSN",
  "SSN_LAST_FOUR_DIGITS",
  "TOBACCO_USE",
  "YEARS_PRACTICE",
  "RTQ_EXPECTED_MONEY_NEED_PERIOD",
  "RTQ_INCOME_PROJECTION",
  "RTQ_FINANCIAL_EMERGENCY_ABILITY",
  "RTQ_INCOME_PROTECTION_STATUS",
  "RTQ_INVESTMENT_KNOWLEDGE",
  "RTQ_INVESTMENT_GOAL",
  "RTQ_COVID_19_INVESTMENT_STRATEGY",
  "RTQ_INVESTMENT_CHECK_FREQUENCY",
  "RTQ_INVESTMENT_PORTFOLIO_CHOICE",
  "RTQ_SCORE",
  "RTQ_TARGET_PORTFOLIO_TYPE",
  "BIRTH_STATE",
  "BORN_IN_THE_USA",
  "NET_WORTH",
  "CURR_LIFE_POLICY_TYPE",
  "CURR_LIFE_POLICY_MONTHLY_PREM",
  "ABOUT_US_REFERRAL",
  "INTERESTS",
  "EULA_ACCEPT_TIMESTAMP",
];

const PROFILE_CLIENT_INFO_FIELDS: (keyof ClientInfo)[] = [
  "FIRST_NAME",
  "LAST_NAME",
  "EMAIL",
  "PHONE_NUMBER",
  "SPECIALTY",
  "CAREER_STAGE",
  "ADDRESS_STREET",
  "ADDRESS_STREET_2",
  "ADDRESS_CITY",
  "ADDRESS_STATE",
  "ADDRESS_ZIP",
  "MAILING_ADDRESS_STREET",
  "MAILING_ADDRESS_STREET_2",
  "MAILING_ADDRESS_CITY",
  "MAILING_ADDRESS_STATE",
  "MAILING_ADDRESS_ZIP",
  "SSN",
  "DOB",
  "EMPLOYMENT_STATUS",
  "EMPLOYER_NAME",
  "OCCUPATION",
  "NPI_NUMBER",
  "DRIVER_LICENSE_STATE",
  "DRIVER_LICENSE_NUMBER",
  "DRIVER_LICENSE_EXP",
  "ANNUAL_INCOME",
  "HAS_FINRA_ASSOCIATION",
];

const DISABILITY_INSURANCE_CLIENT_INFO_FIELDS: (keyof ClientInfo)[] = [
  "FIRST_NAME",
  "LAST_NAME",
  "DOB",
  "ADDRESS_STATE",
  "SPECIALTY",
  "GENDER",
  "ANNUAL_INCOME",
  "YEARS_PRACTICE",
  "TOBACCO_USE",
  "BIRTH_STATE",
  "PLACE_OF_BIRTH",
  "BORN_IN_THE_USA",
];

const RISK_TOLERANCE_CLIENT_INFO_FIELDS: (keyof ClientInfo)[] = [
  "CAREER_STAGE",
  "CAREER_STAGE_EXPANDED",
  "RTQ_EXPECTED_MONEY_NEED_PERIOD",
  "RTQ_INCOME_PROJECTION",
  "RTQ_FINANCIAL_EMERGENCY_ABILITY",
  "RTQ_INCOME_PROTECTION_STATUS",
  "RTQ_INVESTMENT_KNOWLEDGE",
  "RTQ_INVESTMENT_GOAL",
  "RTQ_COVID_19_INVESTMENT_STRATEGY",
  "RTQ_INVESTMENT_CHECK_FREQUENCY",
  "RTQ_INVESTMENT_PORTFOLIO_CHOICE",
  "RTQ_SCORE",
  "RTQ_TARGET_PORTFOLIO_TYPE",
];

const TERM_LIFE_INSURANCE_CLIENT_INFO_FIELDS: (keyof ClientInfo)[] = [
  "FIRST_NAME",
  "LAST_NAME",
  "DOB",
  "ADDRESS_STATE",
  "BIRTH_STATE",
  "PLACE_OF_BIRTH",
  "BORN_IN_THE_USA",
  "ANNUAL_INCOME",
  "NET_WORTH",
  "CURR_LIFE_POLICY_TYPE",
  "CURR_LIFE_POLICY_MONTHLY_PREM",
];

const EMPTY_FIELD_PLACEHOLDER = "--";

export {
  EMPTY_FIELD_PLACEHOLDER,
  CLIENT_INFO_TAB_OPTIONS,
  ALL_CLIENT_INFO_FIELDS,
  PROFILE_CLIENT_INFO_FIELDS,
  DISABILITY_INSURANCE_CLIENT_INFO_FIELDS,
  RISK_TOLERANCE_CLIENT_INFO_FIELDS,
  TERM_LIFE_INSURANCE_CLIENT_INFO_FIELDS,
};
