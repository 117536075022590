import { USE_ACCOUNTS_DATA_KEY, USE_ACCOUNTS_PATH } from "./constants";
import { OrionAccount, OrionRegistration, MxAccount } from "./types";
import { useFormeBackendApi } from "@modules/forme/queries";

const useAccounts = (params = {}, options = {}) => {
  return useFormeBackendApi<(OrionAccount | OrionRegistration | MxAccount)[]>({
    method: "GET",
    path: USE_ACCOUNTS_PATH,
    params,
    dataKey: USE_ACCOUNTS_DATA_KEY,
    options,
  });
};
export { useAccounts };
