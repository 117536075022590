import LoadingIndicator from "@common/components/LoadingIndicator";
import { FC } from "react";

const OnboardingRedirectScreen: FC = () => {
  return (
    <div
      data-testid="onboarding-redirect-screen"
      className="fixed bottom-0 left-0 right-0 top-0 bg-white"
    >
      <LoadingIndicator isVisible={true} />
    </div>
  );
};

export default OnboardingRedirectScreen;
