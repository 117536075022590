import create, { StateCreator } from "zustand";
import { persist, PersistOptions } from "zustand/middleware";

interface AppState {
  isOnboardingCompleted: boolean;
  setIsOnboardingCompleted: (isOnboardingCompleted: boolean) => void;
  isAdvisorChatOpen: boolean;
  setIsAdvisorChatOpen: (isAdvisorChatOpen: boolean) => void;
  isSidebarExpanded: boolean;
  setIsSidebarExpanded: (isSidebarExpanded: boolean) => void;
  desiredPath: string;
  setDesiredPath: (path: string) => void;
  activeSidebarLinkName: string;
  setActiveSidebarLinkName: (path: string) => void;
  currentSpendingView: string;
  setCurrentSpendingView: (view: string) => void;
  showConnectionsDialog: boolean;
  setShowConnectionsDialog: (show: boolean) => void;
  showLogoutDialog: boolean;
  setShowLogoutDialog: (show: boolean) => void;
  isLoggingOut: boolean;
  setIsLoggingOut: (loggingOut: boolean) => void;
  isOpenTalkToAnAdvisorModal: boolean;
  setIsOpenTalkToAnAdvisorModal: (isOpen: boolean) => void;
  isPoFExperience: boolean | undefined;
  setIsPoFExperience: (isPoFExperience: boolean | undefined) => void;
}

type SidebarPersist = (
  config: StateCreator<AppState>,
  options: PersistOptions<AppState>
) => StateCreator<AppState>;

const useStore = create<AppState>(
  (persist as unknown as SidebarPersist)(
    (set) => ({
      isOnboardingCompleted: false,
      setIsOnboardingCompleted: (isOnboardingCompleted) =>
        set({ isOnboardingCompleted }),
      isAdvisorChatOpen: false,
      setIsAdvisorChatOpen: (isAdvisorChatOpen) => set({ isAdvisorChatOpen }),
      isSidebarExpanded: true,
      setIsSidebarExpanded: (isSidebarExpanded) =>
        set({ isSidebarExpanded: isSidebarExpanded }),
      desiredPath: "",
      setDesiredPath: (path) => set({ desiredPath: path }),
      activeSidebarLinkName: "",
      setActiveSidebarLinkName: (name) => set({ activeSidebarLinkName: name }),
      currentSpendingView: "transactions",
      setCurrentSpendingView: (view) => set({ currentSpendingView: view }),
      showConnectionsDialog: false,
      setShowConnectionsDialog: (show) => set({ showConnectionsDialog: show }),
      showLogoutDialog: false,
      setShowLogoutDialog: (show) =>
        set({
          showLogoutDialog: show,
        }),
      isLoggingOut: false,
      setIsLoggingOut: (isLoggingOut) =>
        set({
          isLoggingOut,
        }),
      isOpenTalkToAnAdvisorModal: false,
      setIsOpenTalkToAnAdvisorModal: (show) =>
        set({
          isOpenTalkToAnAdvisorModal: show,
        }),
      isPoFExperience: undefined,
      setIsPoFExperience: (isPoFExperience) =>
        set({
          isPoFExperience,
        }),
    }),
    {
      name: "app-state-storage",
      version: 2,
      partialize: (state) =>
        Object.fromEntries(
          Object.entries(state).filter(
            ([key]) =>
              !["isLoggingOut", "showLogoutDialog", "desiredPath"].includes(key)
          )
        ) as AppState,
    }
  )
);

export { useStore };

export default useStore;
