import { useUser } from "@auth0/nextjs-auth0";
import { CREATED_BY_ADVISOR_CLAIM } from "@common/constants";

const useCreatedByAdvisor = (): boolean | undefined => {
  const { user } = useUser();
  if (user) {
    return user[CREATED_BY_ADVISOR_CLAIM] === true;
  }
  return undefined;
};

export { useCreatedByAdvisor };
