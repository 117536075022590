import { FingerprintScore } from "./types";
import { useFormeBackendApi } from "@modules/forme/queries";

const useFingerprintScores = (options = {}) => {
  return useFormeBackendApi<FingerprintScore[]>({
    method: "GET",
    path: "/v1/fingerprints",
    dataKey: "data",
    options,
  });
};

export { useFingerprintScores };
