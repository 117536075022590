import React from "react";
import { useQuery } from "react-query";
import request from "@common/request";
import { useFormeBackendApi } from "@modules/forme/queries";
import {
  DisabilityInsurancePayload,
  ProfilePayload,
  RiskTolerancePayload,
  TLPayload,
} from "@earned/wizard";
import _ from "lodash";

const useTasks = () =>
  useQuery("taskList", () =>
    request<Forme.EnrichedTask[]>("GET", "/api/tasks")
  );

const useTasksOpenCount = () =>
  useQuery(["getOpenTasksCount"], () =>
    request<Forme.TaskCount>("GET", "/api/tasks/open-count")
  );

const useDisabilityInsuranceInfo = (clientId: string) => {
  const { data, ...response } = useFormeBackendApi<DisabilityInsurancePayload>({
    method: "GET",
    path: "/v1/clients/disability-insurance",
    params: { clientId },
  });

  const disabilityInsuranceInfo = React.useMemo(() => {
    if (data) return _.omitBy(data, _.isNull);
  }, [data]);

  return {
    disabilityInsuranceInfo,
    ...response,
  };
};

const useTermLifeInsuranceInfo = (clientId: string) => {
  const { data, ...response } = useFormeBackendApi<TLPayload>({
    method: "GET",
    path: "/v1/clients/term-life-insurance",
    params: { clientId },
  });

  const termLifeInsuranceInfo = React.useMemo(() => {
    if (data) return _.omitBy(data, _.isNull);
  }, [data]);

  return {
    termLifeInsuranceInfo,
    ...response,
  };
};

const useProfileInfo = () => {
  const { data, ...response } = useFormeBackendApi<ProfilePayload>({
    method: "GET",
    path: "/v1/clients/profile",
  });

  const profileInfo = React.useMemo(() => {
    if (data)
      return {
        client: _.omitBy(data.client, _.isNull),
        coClient: _.omitBy(data.coClient, _.isNull),
      } as ProfilePayload;
  }, [data]);

  return {
    profileInfo,
    ...response,
  };
};
const useRiskToleranceInfo = (clientId: string) => {
  const { data, ...response } = useFormeBackendApi<RiskTolerancePayload>({
    method: "GET",
    path: "/v1/clients/risk-tolerance",
    params: { clientId },
  });

  const riskToleranceInfo = React.useMemo(() => {
    if (data) return _.omitBy(data, _.isNull);
  }, [data]);

  return {
    riskToleranceInfo,
    ...response,
  };
};

export {
  useTasks,
  useTasksOpenCount,
  useDisabilityInsuranceInfo,
  useTermLifeInsuranceInfo,
  useProfileInfo,
  useRiskToleranceInfo,
};
