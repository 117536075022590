import { useIsLocked360View } from "@modules/networth/hooks";
import { useTasksOpenCount } from "@modules/tasks/queries";
import {
  HiOutlineBookmark,
  HiOutlineCalculator,
  HiOutlineCash,
  HiOutlineChartPie,
  HiOutlineChartSquareBar,
  HiOutlineClipboardCheck,
  HiOutlineDocumentText,
  HiOutlineLibrary,
  HiOutlineTrendingUp,
  HiOutlineUsers,
} from "react-icons/hi";

import Image from "next/image";
import pofLogo from "@logos/pof-logo-small.png";
import { ClientRoutes } from "@common/constants";
import { useHousehold } from "@common/queries";
import useAdvisorStore from "@modules/advisor/state";
import useStore from "@common/state";
import { useMemo } from "react";
import { usePoFExperience, useUMPExperience } from "@common/hooks";
import { ClientSidebarNav } from "@common/types";

const useClientNavForWeb = () => {
  const { impersonateClientUserId } = useAdvisorStore();
  const { currentSpendingView, isSidebarExpanded } = useStore();
  const { data: household } = useHousehold();
  const { isLocked360View } = useIsLocked360View();
  const { isLockedInvestments, advisorId, isLockedFinancialPlan } =
    household ?? {};
  const { data: tasksCount } = useTasksOpenCount();

  const { isPof } = usePoFExperience();
  const { isUMPExperience } = useUMPExperience();

  const view360 = useMemo(
    () => ({
      href: ClientRoutes.Overview,
      icon: <HiOutlineChartPie />,
      text: isPof || isUMPExperience ? "Dashboard" : "360° View",
      isLocked: !isPof && !isUMPExperience && isLocked360View,
    }),
    [isLocked360View, isPof, isUMPExperience]
  );
  const financialPlan = useMemo(
    () => ({
      href: ClientRoutes.FinancialPlan,
      icon: <HiOutlineChartSquareBar />,
      text: "Financial Plan",
      isLocked: isLockedFinancialPlan || isPof || isUMPExperience,
    }),
    [isLockedFinancialPlan, isPof, isUMPExperience]
  );
  const documentVault = useMemo(
    () => ({
      href: ClientRoutes.DocumentVault,
      icon: <HiOutlineDocumentText />,
      text: "Document Vault",
      isLocked: isPof || isUMPExperience,
    }),
    [isPof, isUMPExperience]
  );
  const advisoryTeam = useMemo(
    () => ({
      href: ClientRoutes.AdvisoryTeam,
      icon: <HiOutlineUsers />,
      text: "Advisory Team",
      isLocked: !advisorId || isPof || isUMPExperience,
    }),
    [advisorId, isPof, isUMPExperience]
  );
  const tasksAndActions = useMemo(
    () => ({
      href: ClientRoutes.TaskAndActions,
      icon: <HiOutlineClipboardCheck />,
      text: "Tasks and Actions",
      badgeCount: tasksCount?.count,
      isLocked: isPof || isUMPExperience,
    }),
    [tasksCount?.count, isPof, isUMPExperience]
  );
  const forYou = useMemo(
    () => ({
      href: ClientRoutes.ForYou,
      icon: <HiOutlineBookmark />,
      text: "For You",
    }),
    []
  );
  const managedInvestments = useMemo(
    () => ({
      href: ClientRoutes.Investments,
      icon: <HiOutlineTrendingUp />,
      text: "Managed Investments",
      isLocked: isPof || isLockedInvestments || isUMPExperience,
    }),
    [isLockedInvestments, isPof, isUMPExperience]
  );
  const accounts = useMemo(
    () => ({
      href: ClientRoutes.Accounts,
      icon: <HiOutlineLibrary />,
      text: "Accounts",
      confirmMessage: impersonateClientUserId
        ? "Caution: Accessing this page may activate Multi-Factor Authentication (MFA) text messages and push notifications for the client. You can check account balances in the 360 View and manage the client's connected accounts using the 'Manage MX' button in the advisor impersonation portal. Do you wish to proceed?"
        : undefined,
    }),
    [impersonateClientUserId]
  );
  const spending = useMemo(
    () => ({
      href: ClientRoutes.Spending,
      query: { view: currentSpendingView },
      icon: <HiOutlineCash />,
      text: "Spending",
    }),
    [currentSpendingView]
  );
  const budgets = useMemo(
    () => ({
      href: ClientRoutes.Budgets,
      icon: <HiOutlineCalculator />,
      text: "Budgets",
    }),
    []
  );

  const clientSidebar: ClientSidebarNav[] = useMemo(
    () => [
      {
        sectionTitle: "Dashboards",
        containerClassName: `${
          isSidebarExpanded
            ? "sidebar-section-text-expanded"
            : "sidebar-section-text-collapsed"
        }`,
        links: [
          view360,
          financialPlan,
          documentVault,
          advisoryTeam,
          tasksAndActions,
          forYou,
        ],
      },
      {
        sectionTitle: "Your Money",
        containerClassName: `whitespace-nowrap ${
          isSidebarExpanded
            ? "sidebar-section-text-expanded"
            : "sidebar-section-text-collapsed"
        }`,
        links: [managedInvestments, accounts, spending, budgets],
      },
    ],
    [
      accounts,
      advisoryTeam,
      budgets,
      documentVault,
      financialPlan,
      forYou,
      isSidebarExpanded,
      managedInvestments,
      spending,
      tasksAndActions,
      view360,
    ]
  );

  const pofSidebar: ClientSidebarNav[] = useMemo(
    () => [
      {
        sectionTitle: "Your Money",
        containerClassName: `${
          isSidebarExpanded
            ? "sidebar-section-text-expanded"
            : "sidebar-section-text-collapsed"
        }`,
        links: [
          view360,
          {
            href: ClientRoutes.PhysicianOnFire,
            icon: (
              <Image
                src={pofLogo}
                alt="Physician on FIRE logo"
                width={16}
                height={16}
                style={{ objectFit: "contain" }}
              />
            ),
            text: "Physician on FIRE",
          },
          spending,
          budgets,
        ],
      },
      {
        sectionTitle: "Wealth Management",
        containerClassName: `whitespace-nowrap ${
          isSidebarExpanded
            ? "sidebar-section-text-expanded"
            : "sidebar-section-text-collapsed"
        }`,
        links: [
          managedInvestments,
          documentVault,
          tasksAndActions,
          advisoryTeam,
          financialPlan,
        ],
      },
    ],
    [
      advisoryTeam,
      budgets,
      documentVault,
      financialPlan,
      isSidebarExpanded,
      managedInvestments,
      spending,
      tasksAndActions,
      view360,
    ]
  );

  const umpSidebar: ClientSidebarNav[] = useMemo(
    () => [
      {
        sectionTitle: "Your Money",
        containerClassName: `${
          isSidebarExpanded
            ? "sidebar-section-text-expanded"
            : "sidebar-section-text-collapsed"
        }`,
        links: [view360, spending, budgets],
      },
      {
        sectionTitle: "Wealth Management",
        containerClassName: `whitespace-nowrap ${
          isSidebarExpanded
            ? "sidebar-section-text-expanded"
            : "sidebar-section-text-collapsed"
        }`,
        links: [
          managedInvestments,
          documentVault,
          tasksAndActions,
          advisoryTeam,
          financialPlan,
        ],
      },
    ],
    [
      advisoryTeam,
      budgets,
      documentVault,
      financialPlan,
      isSidebarExpanded,
      managedInvestments,
      spending,
      tasksAndActions,
      view360,
    ]
  );

  return isPof ? pofSidebar : isUMPExperience ? umpSidebar : clientSidebar;
};

export default useClientNavForWeb;
