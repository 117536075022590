// /v1/clients/signup

import { SignupFieldValues } from "@earned/wizard/dist";
import { useFormeBackendApi } from "@modules/forme/queries";
import React from "react";
import _ from "lodash";
import { NPINumberResponse } from "./types";
import { UseQueryOptions } from "react-query";

const useSignupInfo = ({ options }: { options?: UseQueryOptions } = {}) => {
  const { data, ...response } = useFormeBackendApi<SignupFieldValues>({
    method: "GET",
    path: "/v1/clients/signup",
    options: { ...options, cacheTime: Infinity },
  });

  const signupInfo = React.useMemo(() => {
    if (data) return _.omitBy(data, _.isNull) as SignupFieldValues;
  }, [data]);

  return {
    signupInfo,
    ...response,
  };
};

const useNPIInfo = ({
  npiNumber,
  enabled,
}: {
  npiNumber: string | undefined;
  enabled: boolean;
}) =>
  useFormeBackendApi<NPINumberResponse>({
    method: "GET",
    path: `/v1/clients/npi-lookup/${npiNumber}`,
    options: {
      enabled,
      retry: (failureCount, error: { status: number }) => {
        // Don't retry if the status code is 404
        if (error.status === 404) {
          return false;
        }
        // Otherwise, retry up to 3 times
        return failureCount < 3;
      },
    },
  });

export { useSignupInfo, useNPIInfo };
