import { ReactNode, useEffect } from "react";
import Image from "next/image";
import Link from "next/link";
import Earned from "@logos/earned.svg";
import E from "@logos/e.svg";
import pof from "@logos/pof-logo.svg";
import ump from "@logos/ump-logo.png";

import {
  HiOutlineChevronDoubleLeft,
  HiOutlineChevronDoubleRight,
} from "react-icons/hi";
import SidebarAdvisorAccess from "@common/components/Sidebar/SidebarAdvisorAccess";
import { themeColors } from "@common/constants";
import useStore from "@common/state";
import {
  useBreakpoints,
  usePoFExperience,
  useUMPExperience,
} from "@common/hooks";
import cx from "classnames";

const Sidebar = ({
  showAdvisorAccess = true,
  children,
}: {
  showAdvisorAccess: boolean;
  children: ReactNode;
}) => {
  const { isSidebarExpanded, setIsSidebarExpanded } = useStore();
  const { isTablet, isDesktop } = useBreakpoints();
  const { isPof } = usePoFExperience();
  const { isUMPExperience } = useUMPExperience();

  useEffect(() => {
    if (isTablet) {
      setIsSidebarExpanded(false);
    } else if (isDesktop) {
      setIsSidebarExpanded(true);
    }
  }, [isTablet, isDesktop, setIsSidebarExpanded]);

  return (
    <div
      className={
        "Sidebar relative hidden h-full flex-col md:inline-flex " +
        (isSidebarExpanded
          ? "sidebar-container-expanded"
          : "sidebar-container-collapsed")
      }
    >
      <div
        className={
          "relative flex flex-none flex-row pt-10 " +
          (isSidebarExpanded
            ? "sidebar-logo-container-expanded"
            : "sidebar-logo-container-collapsed")
        }
      >
        <Link
          href="/"
          aria-label="Earned Wealth Homepage"
          className={
            "absolute left-[37.5px] h-6 flex-none transition-opacity duration-300 " +
            (isSidebarExpanded ? "opacity-0" : "opacity-100")
          }
        >
          <Image src={E} alt="earned logo letter e" width={21} height={24} />
        </Link>
        <Link
          href="/"
          aria-label="Earned Wealth Homepage"
          className={cx(
            "absolute left-10 w-[100px] flex-none transition-opacity duration-300",
            !isSidebarExpanded ? "opacity-0" : "opacity-100",
            isPof && "w-[90px]"
          )}
        >
          {isPof ? (
            <Image src={pof} alt="earned logo" width={133} height={71} />
          ) : isUMPExperience ? (
            <Image src={ump} alt="earned logo" width={133} height={71} />
          ) : (
            <Image src={Earned} alt="earned logo" width={98} height={24} />
          )}
        </Link>
      </div>
      <button
        onClick={() => setIsSidebarExpanded(!isSidebarExpanded)}
        className={
          isSidebarExpanded
            ? "sidebar-arrow-container-expanded"
            : "sidebar-arrow-container-collapsed"
        }
      >
        {isSidebarExpanded ? (
          <HiOutlineChevronDoubleLeft color={themeColors.white} />
        ) : (
          <HiOutlineChevronDoubleRight color={themeColors.white} />
        )}
      </button>
      {children}
      {showAdvisorAccess && <SidebarAdvisorAccess />}
    </div>
  );
};

export default Sidebar;
