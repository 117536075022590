import request from "@common/request";
import { useMutation, useQuery, useQueryClient } from "react-query";

const fetchZendeskAccessToken = (headers: Record<string, string> = {}) =>
  request<{ accessToken: string }>(
    "POST",
    "/api/zendesk-token",
    undefined,
    headers
  );

const useZendeskAccessToken = () => {
  return useQuery("zendeskAccessToken", () => fetchZendeskAccessToken(), {
    cacheTime: 1000 * 60 * 15,
    refetchOnWindowFocus: false,
  });
};

const useUserUpdate = () => {
  return useMutation((changes: { [key: string]: string | boolean }) =>
    request("PATCH", "/api/me", changes)
  );
};

const useUserDetailed = () => {
  return useQuery("me", () => request<Forme.UserDetailed>("GET", "/api/me"));
};

const useMfaReset = () => {
  return useMutation(() =>
    request<{ resetMfa: { url: string } }>("POST", "/api/me/reset-mfa")
  );
};

const useProfile = (options = {}) => {
  return useQuery(
    "profile",
    () => request<Forme.Profile>("GET", "/api/me/profile"),
    options
  );
};

const useProfileUpdate = (options = {}) => {
  const queryClient = useQueryClient();
  return useMutation(
    (
      data: Partial<
        Pick<Forme.Profile, "specialty" | "careerStage" | "npiNumber">
      >
    ) => request<Forme.Profile>("PATCH", "/api/me/profile", data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["currentClient"]);
      },
      ...options,
    }
  );
};

const useMeClient = (options = {}) => {
  return useQuery(
    "currentClient",
    () => request<Forme.ClientWithEmail>("GET", "/api/me/client"),
    options
  );
};

const useHouseholdClients = (options = {}) => {
  return useQuery(
    "householdClients",
    () => request<Forme.Client[]>("GET", "/api/me/household/clients"),
    options
  );
};

const useAdvisors = (userIds: string[]) => {
  return useQuery(
    "myAdvisors",
    () =>
      request<Forme.Advisor[]>("GET", "/api/me/advisors", {
        userIds: userIds.toString(),
      }),
    {
      enabled: userIds?.length > 0,
      initialData: [],
    }
  );
};

const useClientUpdate = (options = {}) => {
  return useMutation(
    (changes: { [key: string]: string | boolean }) =>
      request("PATCH", "/api/me/client", changes),
    options
  );
};

const useHousehold = (options = {}) => {
  return useQuery(
    "household",
    () => request<Forme.HouseholdWithClientUsers>("GET", "/api/me/household"),
    options
  );
};

export {
  fetchZendeskAccessToken,
  useZendeskAccessToken,
  useUserUpdate,
  useUserDetailed,
  useMfaReset,
  useProfile,
  useProfileUpdate,
  useHousehold,
  useAdvisors,
  useHouseholdClients,
  useClientUpdate,
  useMeClient,
};
