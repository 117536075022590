import { AccountSummaryByCategory } from "@modules/networth/types";
import { ACCOUNT_CATEGORY_BY_MX_ACCOUNT_TYPE } from "@modules/networth/constants";
import { currencyFormatter } from "@common/utils";
import {
  Account,
  MxAccount,
  OrionAccount,
  OrionRegistration,
} from "@modules/forme/types";
import { SOURCES, TYPES } from "@modules/forme/constants";
import { sumBy, isNumber, capitalize } from "lodash";

// This will come in handy if we want to render MX type/subtype
// const toTitleCase = (str: string) => {
//   return startCase(str)
//     .split(" ")
//     .map((word: string) => capitalize(word))
//     .join(" ");
// };

interface OrionRegistrationWithAccounts extends OrionRegistration {
  NESTED_ACCOUNTS: OrionAccount[];
  CURRENT_VALUE: number;
  COMPUTED_CUSTODIAN: string | undefined;
  COMPUTED_CUSTODIAN_ACCOUNT_NUMBER: string | undefined;
}

const getOrionRegistrationWithNestedAccounts = (
  accounts: Account[] | undefined
): OrionRegistrationWithAccounts[] => {
  if (!accounts) return [];
  const registrations = Array.from(
    accounts.filter(
      (account) =>
        account.FORME_SOURCE === SOURCES.ORION &&
        account.FORME_TYPE === TYPES.REGISTRATION &&
        !RegExp(/financial plan/i).test(account.ACCOUNT_TYPE?.toString())
    )
  ) as OrionRegistration[];
  const orionAccounts = accounts.filter(
    (account) =>
      account.FORME_SOURCE === SOURCES.ORION &&
      account.FORME_TYPE === TYPES.ACCOUNT &&
      !(account as OrionAccount)?.NUMBER?.toString()?.match(
        /(distribution|contribution)/i
      )
  ) as OrionAccount[];
  return registrations.map((registration) => {
    const nestedAccounts = orionAccounts.filter(
      (account) => registration.ID === account.REGISTRATION_ID
    );
    let currentValue = sumBy(nestedAccounts, "CURRENT_VALUE");
    if (nestedAccounts.length === 0) {
      // Special Case: No nested sleeve accounts.
      currentValue = registration.CURRENT_VALUE;
    }
    const sleeve = nestedAccounts.find((account) => !!account.CUSTODIAN);
    let custodian = sleeve?.CUSTODIAN;
    let custodianAccountNumber = sleeve?.NUMBER?.split("_")?.[0].replace(
      "-",
      ""
    );

    if (!custodian && registration.CREATED_BY.includes("Pontera")) {
      custodian = "Pontera";
      custodianAccountNumber =
        registration.CREATED_BY.split("Pontera ")[1] ?? "";
    }
    return {
      ...registration,
      NESTED_ACCOUNTS: nestedAccounts,
      CURRENT_VALUE: currentValue,
      COMPUTED_CUSTODIAN: custodian,
      COMPUTED_CUSTODIAN_ACCOUNT_NUMBER: custodianAccountNumber,
    };
  });
};

const getAccountDisplayName = (account: Account) => {
  switch (account.FORME_TYPE) {
    case TYPES.ACCOUNT:
      switch (account.FORME_SOURCE) {
        case SOURCES.MX:
          return [(account as MxAccount).NAME].filter((d) => !!d).join(" ");
        case SOURCES.ORION:
          // "John Doe Fidelity Individual 789-1234567"
          return [
            (account as OrionAccount).NAME,
            (account as OrionAccount).CUSTODIAN,
            (account as OrionAccount).ACCOUNT_TYPE,
          ]
            .filter((d) => !!d)
            .join(" ");
      }
    case TYPES.REGISTRATION:
      return [
        (account as OrionRegistration).NAME,
        (account as OrionRegistration).ACCOUNT_TYPE,
      ]
        .filter((d) => !!d)
        .join(" ");
  }
};

const getAccountDisplayValue = (account: Account) => {
  return currencyFormatter(getAccountValue(account), 0);
};

const getAccountValue = (account: Account) =>
  (account as OrionAccount).CURRENT_VALUE ||
  // MX Shows "Current Balance" (BALANCE) on Accounts Page List.
  (isNumber((account as MxAccount).BALANCE)
    ? (account as MxAccount).BALANCE
    : (account as MxAccount).AVAILABLE_BALANCE) ||
  0;

const getAccountCategory = (account: Account): Forme.AccountCategory => {
  switch (account.FORME_SOURCE) {
    case SOURCES.MX:
      return (
        ACCOUNT_CATEGORY_BY_MX_ACCOUNT_TYPE[
          (account as MxAccount).ACCOUNT_TYPE_NAME as MX.AccountType
        ] ?? "OTHER ASSETS"
      );
    case SOURCES.ORION:
      return "INVESTMENTS";
    default:
      return "OTHER ASSETS";
  }
};

const getCategoryDisplay = (category: Forme.AccountCategory) => {
  switch (category) {
    case "ALL ASSETS":
      return "Total Assets";
    case "INVESTMENTS":
      return "Managed Investments";
    case "DEBT":
      return "Total Debt";
    default:
      return category
        .split(" ")
        .map((w) => capitalize(w))
        .join(" ");
  }
};

const buildAccountSummary = (
  memo: AccountSummaryByCategory,
  category: Forme.AccountCategory,
  account: Account
) => {
  const summary = memo[category] || {
    total: 0,
    totalDisplay: "0",
    accounts: [],
    category,
    categoryDisplay: getCategoryDisplay(category),
  };
  if (
    account.FORME_SOURCE === SOURCES.ORION &&
    (account.FORME_TYPE === TYPES.ACCOUNT ||
      account.ACCOUNT_TYPE?.toString()?.match(/financial plan/i))
  )
    return summary;

  summary.total =
    summary.total + (category === "DEBT" ? -1 : 1) * getAccountValue(account);
  summary.totalDisplay = currencyFormatter(
    summary.total,
    0,
    false,
    category === "DEBT" && summary.total !== 0
  );
  summary.accounts = summary.accounts.concat(account);
  return summary;
};

const getAccountSummaryByCategory = (accounts: Account[]) => {
  const result: AccountSummaryByCategory = accounts.reduce(
    (memo: AccountSummaryByCategory, account) => {
      const category = getAccountCategory(account);
      if (category !== "DEBT") {
        memo["ALL ASSETS"] = buildAccountSummary(memo, "ALL ASSETS", account);
      }
      memo[category] = buildAccountSummary(memo, category, account);
      return memo;
    },
    {}
  );
  return result;
};

export {
  getOrionRegistrationWithNestedAccounts,
  getAccountDisplayName,
  getAccountDisplayValue,
  getAccountValue,
  getAccountSummaryByCategory,
  getAccountCategory,
};
