import Bill from "@images/bill.png";
import Matthew from "@images/matthew.jpeg";
import Myhanh from "@images/myhanh.png";
import Coleman from "@images/coleman.jpg";
import Hussein from "@images/hussein.png";
import Jon from "@images/jon.jpg";
import James from "@images/james.png";
import Todd from "@images/todd.png";
import Shannon from "@images/shannon.png";
import Cait from "@images/cait.jpg";

// TODO: don't match on first name - it does not scale
export const getAdvisorImage = (advisorFirstName: string) => {
  switch (advisorFirstName) {
    case "Coleman":
      return Coleman;
    case "Bill":
      return Bill;
    case "Hussein":
      return Hussein;
    case "Matthew":
      return Matthew;
    case "Myhanh":
      return Myhanh;
    case "Jon":
      return Jon;
    case "James":
      return James;
    case "Todd":
      return Todd;
    case "Shannon":
      return Shannon;
    case "Cait":
      return Cait;
    default:
      return Myhanh;
  }
};

export const getAssociateWealthAdvisorName = (advisorFirstName: string) => {
  switch (advisorFirstName) {
    case "Todd":
      return "James";
    case "Myhanh":
      return "Coleman";
    case "Hussein":
      return "Coleman";
    case "Cait":
      return "Jon";
    case "Shannon":
      return "James";
    default:
      return "Coleman";
  }
};
