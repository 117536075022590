import { useEffect } from "react";
import { useHousehold, useMeClient, useProfile } from "@common/queries";
import useAdvisorStore from "@modules/advisor/state";
import { useSignupInfo } from "@modules/signup/queries";

const useHeapInitialize = (
  heapInjection: Forme.HeapType,
  isAdvisor: boolean,
  user?: Pick<Forme.Claims, "email" | "sub">
) => {
  const { data: client, isLoading: isLoadingClient } = useMeClient({
    enabled: !!user,
  });
  const { data: profile, isLoading: isLoadingProfile } = useProfile({
    enabled: !!user,
  });
  const { data: household, isLoading: isLoadingHousehold } = useHousehold({
    enabled: !!user,
  });
  const { impersonateClientUserId } = useAdvisorStore();

  const { signupInfo, isLoading: isLoadingSignupInfo } = useSignupInfo({
    options: { enabled: !!user },
  });

  const aboutUsReferral = signupInfo?.ABOUT_US_REFERRAL;

  useEffect(() => {
    if (!user || !user.email || !user.sub || !client || !profile || !household)
      return;

    if (isAdvisor) {
      heapInjection?.identify(`Advisor-${user.sub}`);
      heapInjection?.addUserProperties({
        email: user.email,
      });
      if (impersonateClientUserId) {
        heapInjection?.clearEventProperties();
        heapInjection?.addEventProperties({
          impersonateClientUserId,
        });
      }
    } else {
      heapInjection?.identify(client.id);
      heapInjection?.addUserProperties({
        "name": client.firstName
          ? `${client.firstName} ${client.lastName}`
          : client.email,
        "email": client.email,
        "Career Stage": profile?.careerStage,
        "Specialty": profile?.specialty,
        "Investments": !household.isLockedInvestments,
        "Financial Plan": !household.isLockedFinancialPlan,
        "Household ID": household.id,
        "Primary Client": client.isPrimary,
        "Role":
          household.salesforceAccountClientStage !== "Client"
            ? "Member"
            : "Client",
        "About Us Referral": aboutUsReferral || "UNKNOWN",
      });
    }
  }, [
    isAdvisor,
    client,
    impersonateClientUserId,
    profile,
    heapInjection,
    user,
    household,
    aboutUsReferral,
  ]);
  return {
    isLoading:
      isLoadingHousehold ||
      isLoadingProfile ||
      isLoadingClient ||
      isLoadingSignupInfo,
  };
};

export { useHeapInitialize };
