import { FC, Fragment, useEffect, useRef } from "react";
import useStore from "@common/state";
import { Popover, Transition } from "@headlessui/react";
import { HiOutlineMenu, HiOutlineX } from "react-icons/hi";
import SidebarAdvisorAccess from "../Sidebar/SidebarAdvisorAccess";
import MobileClientLinks from "../MobileClientLinks";
import MobileAdvisorLinks from "../MobileAdvisorLinks";
import { useBreakpoints, useHeaderTitle } from "@common/hooks";
import LogoHeader from "../LogoHeader";
import { Typography } from "@earned/core";
interface MobileMenuProps {
  isAdvisor: boolean;
}

const MobileMenu: FC<MobileMenuProps> = ({ isAdvisor }) => {
  const closeRef = useRef<HTMLButtonElement>(null);
  const handleClickPanel = () => closeRef?.current?.click();
  const { setIsSidebarExpanded } = useStore();
  const { isMobile } = useBreakpoints();
  const title = useHeaderTitle();

  useEffect(() => {
    if (isMobile) setIsSidebarExpanded(true);
  }, [isMobile, setIsSidebarExpanded]);

  return (
    <>
      <Popover className="Sidebar relative z-[70] md:hidden">
        {({ open }) => {
          return (
            <>
              <div
                className="Sidebar pointer-events-none absolute inset-0 z-50 shadow"
                aria-hidden="true"
              />
              <div className="relative z-50">
                <div className="mx-auto flex h-[72px] max-w-7xl flex-row items-center justify-between px-10 py-6">
                  <div className="relative flex-1">
                    <Transition
                      show={open}
                      enter="transition-opacity duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="transition-opacity duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                      className="absolute left-0 top-1/2 -translate-y-1/2"
                    >
                      <LogoHeader />
                    </Transition>
                    <Transition
                      show={!open}
                      enter="transition-opacity duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="transition-opacity duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                      className="absolute left-0 top-1/2 -translate-y-1/2"
                    >
                      <Typography
                        variant="body-xl"
                        weight="medium"
                        color="light"
                      >
                        {title}
                      </Typography>
                    </Transition>
                  </div>
                  <Popover.Button
                    ref={closeRef}
                    className="inline-flex items-center justify-center rounded-md focus:outline-none md:hidden"
                  >
                    {!open ? (
                      <>
                        <span className="sr-only">Open menu</span>
                        <HiOutlineMenu
                          className="h-6 w-6"
                          aria-hidden="true"
                          color="white"
                        />
                      </>
                    ) : (
                      <>
                        <span className="sr-only">Close menu</span>
                        <HiOutlineX
                          className="h-6 w-6"
                          aria-hidden="true"
                          color="white"
                        />
                      </>
                    )}
                  </Popover.Button>
                </div>
              </div>
              {open && <div className="absolute h-[100rem] w-full"></div>}
              <Transition
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-y-full"
                enterTo="translate-y-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-y-0"
                leaveTo="-translate-y-full"
              >
                <Popover.Panel
                  focus
                  className="Sidebar absolute inset-x-0 top-full z-40 block h-[calc(100vh-72px)] origin-top transform overflow-y-auto shadow-lg transition"
                >
                  <div className="h-full shadow-lg overflow-x-hidden scrollbar-none">
                    <div className="mt-6 sm:mt-8">
                      <nav>
                        {!isAdvisor ? (
                          <>
                            <MobileClientLinks onClick={handleClickPanel} />
                            <div className="mt-8 pb-[136px]">
                              <SidebarAdvisorAccess
                                onClick={handleClickPanel}
                              />
                            </div>
                          </>
                        ) : (
                          <MobileAdvisorLinks onClick={handleClickPanel} />
                        )}
                      </nav>
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          );
        }}
      </Popover>
    </>
  );
};

export default MobileMenu;
