import { useBreakpoints } from "@common/hooks";
import { Button, Typography } from "@earned/core";
import * as React from "react";
import { HiX } from "react-icons/hi";
import cx from "classnames";
import { useUser } from "@auth0/nextjs-auth0";
import { useHousehold } from "@common/queries";

const ReferralBanner: React.FC<{ isOpen: boolean; close: () => void }> = ({
  isOpen,
  close,
}) => {
  const [showReferralBanner, setShowReferralBanner] = React.useState(false);
  const { isDesktop, isTablet } = useBreakpoints();

  const referralLink = React.useMemo(() => {
    return "https://hello.earnedwealth.com/referralprogram";
  }, []);

  const className = React.useMemo(
    () => cx("z-[80] h-16", { hidden: !isOpen }),
    [isOpen]
  );
  const { user } = useUser();
  const { data: household } = useHousehold({
    enabled: !!user,
  });

  React.useEffect(() => {
    const isLoaded = !!household;
    const isMember = !household?.currentProduct;
    const isWealthDiagnosticClient =
      household?.currentProduct?.templateId === "wealthDiagnostic";
    if (isLoaded && !isMember && !isWealthDiagnosticClient) {
      setShowReferralBanner(true);
    }
  }, [household, setShowReferralBanner]);

  if (!showReferralBanner) return null;

  return (
    <div
      id="referral-program"
      data-testid="referral-banner"
      className={className}
    >
      <div className="relative h-16 bg-[url('/images/referral-banner.png')] bg-cover bg-[top_left] md:bg-contain">
        <div className="flex h-full flex-col items-center justify-center gap-2 md:flex-row md:gap-7 lg:justify-start lg:pl-[328px]">
          {isDesktop || isTablet ? (
            <>
              <Typography variant="title-md" color="tertiary" weight="medium">
                Give $500, Earn $500
              </Typography>
              <div className="flex">
                <Button
                  id="open-referral-page"
                  variant="primary"
                  onClick={() => window.open(referralLink, "_blank")}
                >
                  Learn more
                </Button>
              </div>
            </>
          ) : (
            <div className="flex">
              <Button
                id="open-referral-page-mobile"
                variant="primary"
                onClick={() => window.open(referralLink, "_blank")}
              >
                Give $500, Earn $500
              </Button>
            </div>
          )}
        </div>
        <div className="absolute right-2 top-0 flex h-full flex-col justify-center md:right-7">
          <HiX
            id="close-referral"
            color="white"
            size={24}
            className="transition hover:cursor-pointer hover:opacity-80"
            onClick={close}
          />
        </div>
      </div>
    </div>
  );
};

export default ReferralBanner;
