import { SignupFieldValues } from "@earned/wizard/dist";
import { useMutationFormeBackendApi } from "@modules/forme/queries";

const useUpdateSignupInfo = () => {
  return useMutationFormeBackendApi<void, { data: SignupFieldValues }>({
    method: "PUT",
    path: "/v1/clients/signup",
  });
};

export { useUpdateSignupInfo };
