import { cloneElement, useCallback, useEffect, useRef } from "react";

import { HiOutlineLockClosed } from "react-icons/hi";
import Link from "next/link";
import { useHover } from "usehooks-ts";
import { useRouter } from "next/router";
import useStore from "@common/state";
import { themeColors } from "@common/constants";
import { Typography } from "@earned/core";
import { ISidebarLink } from "@common/types";

const SidebarLink = ({
  icon,
  text,
  query,
  href,
  disabled,
  isLocked,
  confirmMessage,
  onClickLink,
  badgeCount = 0,
}: ISidebarLink) => {
  const router = useRouter();
  const hoverRef = useRef(null);
  const isHover = useHover(hoverRef);

  const {
    desiredPath,
    setDesiredPath,
    setActiveSidebarLinkName,
    isSidebarExpanded,
  } = useStore();

  const hasActivity = href === "/tasks-and-actions"; //hardcoded

  const isActiveLink = (desiredPath ? desiredPath : router.pathname) === href;

  const navigate = useCallback(() => {
    setDesiredPath(href);
    if (onClickLink) onClickLink();
  }, [href, onClickLink, setDesiredPath]);

  const onClick = useCallback(
    (event: { preventDefault: () => void }) => {
      if (!disabled) {
        if (confirmMessage) {
          if (window.confirm(confirmMessage)) {
            navigate();
          } else {
            event.preventDefault();
          }
        } else {
          navigate();
        }
      }
    },
    [disabled, confirmMessage, navigate]
  );

  useEffect(() => {
    if (isActiveLink) {
      setActiveSidebarLinkName(text);
    }
  }, [isActiveLink, text, setActiveSidebarLinkName]);

  let params;
  if (query) {
    params = new URLSearchParams(query);
  }

  return (
    <Link
      href={params ? href + "?" + params.toString() : href}
      shallow
      ref={hoverRef}
      className={
        "SidebarLink group relative flex h-10 min-h-10 flex-row items-center focus-visible:outline-1 " +
        `${isActiveLink ? " SidebarLink--active " : ""}` +
        `${isSidebarExpanded ? "px-10 " : "px-10 "}` +
        `${disabled ? "pointer-events-none cursor-default" : ""}`
      }
      onClick={onClick}
    >
      <div
        className={`absolute left-0 h-8 border-l-2 transition transition-opacity duration-500 ease-in-out ${
          isActiveLink ? "opacity-100" : "opacity-0"
        }`}
      />
      {cloneElement(icon, {
        color: isActiveLink
          ? "white"
          : isHover
          ? themeColors.white
          : themeColors.green["50"],
        alt: text,
        size: "1rem",
        className: "flex-none",
      })}
      <div
        className={
          "ml-2 flex-none transition-opacity duration-300 ease-in" +
          `${isSidebarExpanded ? " opacity-100" : " md:opacity-0"}`
        }
      >
        <Typography variant="body" weight="medium">
          {text}
        </Typography>
      </div>
      {isLocked ? (
        <div
          className={
            "absolute flex-none transition-all " +
            `${
              isSidebarExpanded ? "right-10 top-[11px] h-2.5 w-2.5" : "hidden"
            }`
          }
        >
          <HiOutlineLockClosed
            color={
              isActiveLink
                ? themeColors.white
                : isHover
                ? themeColors.white
                : themeColors.green["50"]
            }
          />
        </div>
      ) : (
        hasActivity &&
        badgeCount > 0 && (
          <div
            className={
              "SidebarDot z-2 absolute flex flex-row items-center justify-center rounded-full font-mono transition-all " +
              `${
                isSidebarExpanded
                  ? "right-[32px] h-5 w-5"
                  : "right-8 h-1.5 w-1.5"
              }`
            }
          >
            <div
              className={
                "whitespace-nowrap text-center text-label-sm font-bold text-white " +
                `${isSidebarExpanded ? " " : "hidden"}`
              }
            >
              {badgeCount}
            </div>
          </div>
        )
      )}
    </Link>
  );
};

export default SidebarLink;
