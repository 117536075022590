import { FC } from "react";
import Dialog from "@common/components/Dialog";
import useStore from "@common/state";
import { useLogout } from "@common/hooks";
import { CgSpinner } from "react-icons/cg";
import { Button, Typography } from "@earned/core";

const LogoutModal: FC = () => {
  const { showLogoutDialog, setShowLogoutDialog, isLoggingOut } = useStore();
  const { logout } = useLogout();
  return (
    <Dialog
      onClose={() => setShowLogoutDialog(false)}
      isOpen={showLogoutDialog}
      title="Logout"
    >
      <div className="flex flex-col space-y-6">
        <Typography variant="body" color="primary">
          Are you sure you want to log out of your Earned account?
        </Typography>
        <div className="flex flex-row justify-end space-x-2">
          <Button variant="tertiary" onClick={() => setShowLogoutDialog(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={logout}>
            {isLoggingOut ? (
              <CgSpinner className="animate-spin" size={20} />
            ) : (
              "Log out"
            )}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default LogoutModal;
