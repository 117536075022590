import * as React from "react";
import Link from "next/link";
import Image from "next/image";
import { usePoFExperience, useUMPExperience } from "@common/hooks";
import pofMobile from "@logos/pof-logo-mobile.png";
import umpMobile from "@logos/ump-logo-mobile.png";
import Earned from "@logos/earned.svg";
import cx from "classnames";

const LogoHeader: React.FC = () => {
  const { isPof } = usePoFExperience();
  const { isUMPExperience } = useUMPExperience();
  return (
    <>
      <Link href="/" className={cx("flex", !isPof && "pt-2")}>
        <span className="sr-only">Earned Wealth</span>
        {isPof ? (
          <Image src={pofMobile} alt="earned logo" width={235} height={40} />
        ) : isUMPExperience ? (
          <Image src={umpMobile} alt="earned logo" width={225} height={42} />
        ) : (
          <Image src={Earned} alt="earned logo" width={100} height={72} />
        )}
      </Link>
    </>
  );
};

export default LogoHeader;
