import {
  HiOutlineCog,
  HiOutlineInformationCircle,
  HiOutlineLogout,
} from "react-icons/hi";

import { ClientRoutes } from "@common/constants";
import { FC } from "react";
import SidebarLink from "../Sidebar/SidebarLink";
import useClientNav from "../Sidebar/useClientNav";
import { useLogout } from "@common/hooks";

interface MobileClientLinksProps {
  onClick: () => void;
}

const MobileClientLinks: FC<MobileClientLinksProps> = ({ onClick }) => {
  const sidenav = useClientNav();
  const { setShowLogoutDialog } = useLogout();

  return (
    <div className="grid gap-y-3">
      {sidenav.map((section, index) => {
        return (
          <div key={index}>
            <div className={`sidebar-section-header px-10`}>
              <span className="sidebar-section-text-expanded whitespace-nowrap text-label-body">
                {section.sectionTitle}
              </span>
            </div>
            {section.links.map((link, index) => {
              return (
                <SidebarLink
                  key={index}
                  href={link.href}
                  icon={link.icon}
                  text={link.text}
                  isLocked={link.isLocked}
                  badgeCount={link.badgeCount}
                  confirmMessage={link.confirmMessage}
                  onClickLink={onClick}
                />
              );
            })}
          </div>
        );
      })}

      <div>
        <div className={"sidebar-section-header px-10 "}>
          <div className="sidebar-section-text-expanded whitespace-nowrap">
            Settings
          </div>
        </div>
        <SidebarLink
          href={ClientRoutes.Settings}
          icon={<HiOutlineCog />}
          text="Profile and Privacy"
          onClickLink={onClick}
        />
        <SidebarLink
          href={ClientRoutes.Disclosures}
          icon={<HiOutlineInformationCircle />}
          text="Disclosures"
          onClickLink={onClick}
        />
        <SidebarLink
          text="Log Out"
          icon={<HiOutlineLogout />}
          href=""
          onClickLink={() => setShowLogoutDialog(true)}
        />
      </div>
    </div>
  );
};

export default MobileClientLinks;
