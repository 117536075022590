import * as React from "react";
import { useUser } from "@auth0/nextjs-auth0";
import useStore from "@common/state";
import { advisorMeetingSetup } from "@common/utils";
import Dialog from "@common/components/Dialog";
import { useHousehold, useMeClient } from "@common/queries";

const TalkToAnAdvisorModal: React.FC = () => {
  const { isOpenTalkToAnAdvisorModal, setIsOpenTalkToAnAdvisorModal } =
    useStore();
  const { user } = useUser();
  const { data: household } = useHousehold();
  const { data: client } = useMeClient();
  const [isShowingCalendly, setIsShowingCalendly] = React.useState(false);
  const [calendlyParentElement, setCalendlyParentElement] =
    React.useState(null);

  const calendlyRef = React.useCallback((node: any) => {
    setCalendlyParentElement(node);
  }, []);

  React.useEffect(() => {
    if (
      household &&
      user &&
      client &&
      isOpenTalkToAnAdvisorModal &&
      calendlyParentElement &&
      !isShowingCalendly
    ) {
      const meetingProps = advisorMeetingSetup(
        household?.advisor,
        user as Forme.Claims,
        client
      );
      window.Calendly.initInlineWidget({
        ...meetingProps,
        parentElement: calendlyParentElement,
      });
      setIsShowingCalendly(true);
    }
  }, [
    client,
    household?.advisor,
    user,
    isOpenTalkToAnAdvisorModal,
    isShowingCalendly,
    calendlyRef,
    calendlyParentElement,
    household,
  ]);

  const trackCalendlyInteractions = (e: MessageEvent) => {
    if (
      e.origin === "https://calendly.com" &&
      e.data.event &&
      e.data.event.indexOf("calendly.") === 0
    ) {
      window.heap?.track(`Calendly ${e.data.event}`, {
        details: e.data.payload?.toString(),
      });
    }
  };

  React.useEffect(() => {
    if (isOpenTalkToAnAdvisorModal) {
      window.addEventListener("message", trackCalendlyInteractions);
    } else {
      window.removeEventListener("message", trackCalendlyInteractions);
      setIsShowingCalendly(false);
    }
    return () => {
      window.removeEventListener("message", trackCalendlyInteractions);
    };
  }, [isOpenTalkToAnAdvisorModal]);

  return (
    <Dialog
      isOpen={isOpenTalkToAnAdvisorModal}
      onClose={() => setIsOpenTalkToAnAdvisorModal(false)}
      title="Talk to an advisor"
      subtitle="If you need to talk to an advisor, we're here to help. Schedule a call below."
      classNames={{
        Panel: "mt-18 md:mt-0",
      }}
    >
      <div ref={calendlyRef} className="h-[60vh] md:h-[60vh]"></div>
    </Dialog>
  );
};

export default TalkToAnAdvisorModal;
