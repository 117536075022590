import { themeColors } from "@common/constants";

export const colors = [
  themeColors["purple-2"][900],
  themeColors["green"][900],
  themeColors["green"][700],
  themeColors["green"][500],
  themeColors["green"][300],
  themeColors["green"][100],
  themeColors["gray"][200],
];

export const colorClassNames = [
  "bg-purple-2-900",
  "bg-green-900",
  "bg-green-700",
  "bg-green-500",
  "bg-green-300",
  "bg-green-100",
  "bg-gray-200",
];

export const ACCOUNT_CATEGORY_BY_MX_ACCOUNT_TYPE: {
  [accountType in MX.AccountType]: Forme.AccountCategory;
} = {
  CASH: "OTHER ASSETS",
  CHECKING: "OTHER ASSETS",
  SAVINGS: "OTHER ASSETS",
  PREPAID: "OTHER ASSETS",
  INVESTMENT: "OTHER ASSETS",
  ANY: "OTHER ASSETS",
  PROPERTY: "OTHER ASSETS",
  INSURANCE: "OTHER ASSETS",
  CREDIT_CARD: "DEBT",
  LINE_OF_CREDIT: "DEBT",
  MORTGAGE: "DEBT",
  LOAN: "DEBT",
};

export const USE_ACCOUNTS_DATA_KEY = "data";
export const USE_ACCOUNTS_PATH = "/v1/accounts";
