import create, { StateCreator } from "zustand";
import { BoxFile } from "@modules/box/types";
import { persist, PersistOptions } from "zustand/middleware";

interface DocVaultState {
  currentFolderId: string;
  previewFile: BoxFile | null;
  previewCollection: BoxFile[];
  previewTopNavFileName: BoxFile["name"] | null;
  setCurrentFolderId: (folderId: string) => void;
  setPreviewFile: (file: BoxFile | null) => void;
  setPreviewCollection: (files: BoxFile[]) => void;
  setPreviewTopNavFileName: (name: BoxFile["name"]) => void;
  resetPreview: () => void;
  filesToUpload: File[] | FileList;
  setFilesToUpload: (files: File[] | FileList) => void;
  showFileUploader: boolean;
  setShowFileUploader: (show: boolean) => void;
  computed: {
    isFileUploadDialogOpen: () => boolean;
  };
  fileToRename: BoxFile | null;
  fileToMove: BoxFile | null;
  fileToDelete: BoxFile | null;
  setFileToRename: (file: BoxFile | null) => void;
  setFileToMove: (file: BoxFile | null) => void;
  setFileToDelete: (file: BoxFile | null) => void;
  showFileSelectModal: boolean;
  setShowFileSelectModal: (show: boolean) => void;
  // FileDecryptDialog state variables:
  showFileDecryptModal: boolean;
  setShowFileDecryptModal: (show: boolean) => void;
  fileToDecrypt: File | any;
  setFileToDecrypt<T>(file: T | null): void;
  setFileDecryptDialogCloseHandler: (handler: (() => void) | undefined) => void;
  setFileDecryptDialogSubmitHandler<T>(
    handler: ((decryptedFile: T) => void) | undefined
  ): void;
  fileDecryptDialogCloseHandler: (() => void) | undefined;
  fileDecryptDialogSubmitHandler:
    | ((decryptedFile: File | any) => void)
    | undefined;
}

const isReactNative =
  typeof navigator !== "undefined" && navigator.product === "ReactNative";

let AsyncStorage: any;

if (isReactNative) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const asyncStorage = require("@react-native-async-storage/async-storage");
  AsyncStorage = asyncStorage.default;
}

export const useStore = create<DocVaultState>((set, get) => ({
  currentFolderId: "",
  previewFile: null,
  previewCollection: [],
  previewTopNavFileName: null,
  setCurrentFolderId: (folderId) => set({ currentFolderId: folderId }),
  setPreviewFile: (file) => set({ previewFile: file }),
  setPreviewCollection: (files) => set({ previewCollection: files }),
  setPreviewTopNavFileName: (name) => set({ previewTopNavFileName: name }),
  resetPreview: () =>
    set({
      previewFile: null,
      previewTopNavFileName: null,
      previewCollection: [],
    }),
  filesToUpload: [],
  setFilesToUpload: (files) => set({ filesToUpload: files }),
  showFileUploader: false,
  setShowFileUploader: (show) => set({ showFileUploader: show }),
  computed: {
    isFileUploadDialogOpen: () =>
      Array.from(get().filesToUpload as File[]).length > 0,
  },
  fileToRename: null,
  fileToMove: null,
  fileToDelete: null,
  setFileToRename: (file) => set({ fileToRename: file }),
  setFileToMove: (file) => set({ fileToMove: file }),
  setFileToDelete: (file) => set({ fileToDelete: file }),
  showFileSelectModal: false,
  setShowFileSelectModal: (show) => set({ showFileSelectModal: show }),
  // FileDecryptDialog state variables:
  setFileToDecrypt: (file) => set({ fileToDecrypt: file }),
  fileToDecrypt: null,
  setShowFileDecryptModal: (show) => set({ showFileDecryptModal: show }),
  showFileDecryptModal: false,
  fileDecryptDialogCloseHandler: undefined,
  setFileDecryptDialogCloseHandler: (handler) =>
    set({ fileDecryptDialogCloseHandler: handler }),
  fileDecryptDialogSubmitHandler: undefined,
  setFileDecryptDialogSubmitHandler: (handler) =>
    set({ fileDecryptDialogSubmitHandler: handler }),
}));

interface DocVaultPersistState {
  boxRootFolderId: string;
  setBoxRootFolderId: (boxRootFolderId: string) => void;
  boxPrivateFolderId: string;
  setBoxPrivateFolderId: (boxPrivateFolderId: string) => void;
}

type BoxPersist = (
  config: StateCreator<DocVaultPersistState>,
  options: PersistOptions<DocVaultPersistState>
) => StateCreator<DocVaultPersistState>;

export const usePersistStore = create<DocVaultPersistState>(
  (persist as unknown as BoxPersist)(
    (set) => ({
      boxRootFolderId: "",
      setBoxRootFolderId: (state) => set({ boxRootFolderId: state }),
      boxPrivateFolderId: "",
      setBoxPrivateFolderId: (state) => set({ boxPrivateFolderId: state }),
    }),
    {
      name: "docvault-state-storage",
      version: 1,
      getStorage: () => (isReactNative ? AsyncStorage : localStorage),
    }
  )
);
