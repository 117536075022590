import {
  SignupFieldValues,
  SignupWizard,
  SignupFieldKeys,
  SIGNUP_FORM_SCHEMA,
  SignupStepNames,
  AboutUsReferral,
  UMP_ACCESS_CODE,
} from "@earned/wizard";
import * as React from "react";
import useStore from "@common/state";
import { useSignupStore } from "@modules/signup/state";
import { useNPIInfo, useSignupInfo } from "@modules/signup/queries";
import { useUpdateSignupInfo } from "@modules/signup/mutations";
import { useRouterQueryParam, useUMPExperience } from "@common/hooks";
import { ClientRoutes } from "@common/constants";
import { reach } from "yup";
import { useCreatedByAdvisor } from "@modules/signup/hooks";
import { useUser } from "@auth0/nextjs-auth0";
import { UMP_AUTOFILL_TOKENS } from "@modules/signup/constants";

const SignupWizardController: React.FC = () => {
  const { setShowLogoutDialog } = useStore();
  const [isOpen, setIsOpen] = React.useState(true);
  const {
    fields: cache,
    navHistory,
    setNavHistory,
    setField,
  } = useSignupStore();
  const { signupInfo, isLoading } = useSignupInfo();
  const { mutateAsync, isLoading: isSubmitting } = useUpdateSignupInfo();
  const { getQueryParamAsURLSearchParams } = useRouterQueryParam("ref");
  const { isUMPExperienceEnabled } = useUMPExperience();
  const { user } = useUser();
  /**
   * let cache override signup info to account for cases where user only partially completed wizard.
   * Since cache gets cleared after submit this is the only scenario in which the cache would be different from API
   */
  const signupInfoCache = React.useMemo(
    () => ({ ...signupInfo, ...cache }),
    [signupInfo, cache]
  );

  const handlePageChange = React.useCallback(
    (page: SignupStepNames, navHistory: SignupStepNames[]) => {
      setNavHistory(navHistory);
      if (navHistory[navHistory.length - 1] !== page) {
        window.heap?.track("Onboarding Page Change", { page });
      }
    },
    [setNavHistory]
  );

  const handleSubmit = React.useCallback(
    async (fields: SignupFieldValues) => {
      await mutateAsync({ data: fields });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (useSignupStore as any).persist.clearStorage();
      setIsOpen(false);
      window.location.href =
        ClientRoutes.Overview +
        "?" +
        getQueryParamAsURLSearchParams({ replaceParams: true }).toString();
    },
    [mutateAsync, getQueryParamAsURLSearchParams]
  );

  const npiNumberSchema = reach(SIGNUP_FORM_SCHEMA, "NPI_NUMBER");

  const { data: npiInfo } = useNPIInfo({
    npiNumber: cache.NPI_NUMBER,
    enabled:
      npiNumberSchema.isValidSync(cache.NPI_NUMBER) === true &&
      !cache.NOT_A_DOCTOR,
  });

  React.useEffect(() => {
    if (npiInfo) {
      setField({
        key: SignupFieldKeys.SPECIALTY,
        value: npiInfo.SPECIALTY,
      });
      setField({
        key: SignupFieldKeys.SUBSPECIALTY,
        value: npiInfo.SUBSPECIALTY,
      });
    }
  }, [npiInfo, setField]);

  const createdByAdvisor = useCreatedByAdvisor();
  React.useEffect(() => {
    if (createdByAdvisor !== undefined) {
      setField({
        key: SignupFieldKeys.CREATED_BY_ADVISOR,
        value: createdByAdvisor,
      });
      if (createdByAdvisor === true) {
        setField({
          key: SignupFieldKeys.ABOUT_US_REFERRAL,
          value: undefined,
        });
        setField({
          key: SignupFieldKeys.INTERESTS,
          value: [],
        });
      }
    }
  }, [createdByAdvisor, setField]);

  const onFieldChange = React.useCallback(
    (field: { key: keyof SignupFieldValues; value: unknown }) => {
      setField(field);

      if (field.key === SignupFieldKeys.NOT_A_DOCTOR && field.value) {
        setField({
          key: SignupFieldKeys.NPI_NUMBER,
          value: "",
        });
        setField({
          key: SignupFieldKeys.CAREER_STAGE,
          value: null,
        });
        setField({
          key: SignupFieldKeys.SPECIALTY,
          value: "",
        });
        setField({
          key: SignupFieldKeys.SUBSPECIALTY,
          value: "",
        });
      }

      if (field.key === SignupFieldKeys.NPI_NUMBER) {
        setField({
          key: SignupFieldKeys.SPECIALTY,
          value: "",
        });
        setField({
          key: SignupFieldKeys.SUBSPECIALTY,
          value: "",
        });
      }
    },
    [setField]
  );

  React.useEffect(() => {
    if (
      UMP_AUTOFILL_TOKENS.some((token) => token.test(user?.email ?? "")) &&
      isUMPExperienceEnabled
    ) {
      setField({
        key: SignupFieldKeys.ACCESS_CODE,
        value: UMP_ACCESS_CODE,
      });
      setField({
        key: SignupFieldKeys.ABOUT_US_REFERRAL,
        value: AboutUsReferral.OTHER,
      });
      setField({
        key: SignupFieldKeys.SHOW_ACCESS_CODE_HELPER_TEXT,
        value: true,
      });
    }
  }, [setField, user, isUMPExperienceEnabled]);

  return (
    <SignupWizard
      isOpen={isOpen}
      close={() => setShowLogoutDialog(true)}
      onDone={() => null}
      onSubmit={handleSubmit}
      isSubmitting={isSubmitting}
      initialNavHistory={navHistory}
      onPageChange={handlePageChange}
      cache={signupInfoCache}
      isLoading={isLoading || !signupInfo}
      onFieldChange={onFieldChange}
      showAboutUsReferralQuestion={createdByAdvisor === false}
      showAccessCodeInput={isUMPExperienceEnabled}
    />
  );
};

export default SignupWizardController;
