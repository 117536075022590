import create, { StateCreator } from "zustand";
import { persist, PersistOptions } from "zustand/middleware";
interface MxState {
  isMxZeroState: boolean;
  setIsMxZeroState: (isZeroState: boolean) => void;
}

type MxPersist = (
  config: StateCreator<MxState>,
  options: PersistOptions<MxState>
) => StateCreator<MxState>;

const isReactNative =
  typeof navigator !== "undefined" && navigator.product === "ReactNative";

let AsyncStorage: any;

if (isReactNative) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const asyncStorage = require("@react-native-async-storage/async-storage");
  AsyncStorage = asyncStorage.default;
}

const useStore = create<MxState>(
  (persist as unknown as MxPersist)(
    (set) => ({
      isMxZeroState: true,
      setIsMxZeroState: (state) => set({ isMxZeroState: state }),
    }),
    {
      name: "mx-state-storage",
      version: 1,
      getStorage: () => (isReactNative ? AsyncStorage : localStorage),
    }
  )
);

export default useStore;
