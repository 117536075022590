/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/display-name */
import { useIsAdvisor, usePoFExperience, useRouter } from "@common/hooks";
import Cookies from "js-cookie";
import { useUser } from "@auth0/nextjs-auth0";
import useStore from "@common/state";
import { ClientRoutes, CustomCookies } from "@common/constants";
import { ComponentType, useCallback, useEffect } from "react";
import { useMeClient } from "@common/queries";
import { OnboardingRedirectScreen } from "@modules/signup";

type WithPageAuthRequiredOptions = {
  isOnboardingRequired: boolean;
  isAdvisorOnly?: boolean;
  isPoF?: boolean;
};

// Copied from @auth0/nextjs-auth0 source code, modified to support conditional redirect to signup vs login
const withPageAuthRequiredAuth0 = (
  Component: ComponentType<any>,
  options: Record<string, string> = {}
) => {
  return function WithPageAuthRequired(props: any): JSX.Element {
    const { returnTo } = options;
    const { user, error, isLoading } = useUser();

    useEffect(() => {
      if ((user && !error) || isLoading) return;
      let returnToPath: string;

      if (!returnTo) {
        const currentLocation = window.location.toString();
        returnToPath =
          currentLocation.replace(new URL(currentLocation).origin, "") || "/";
      } else {
        returnToPath = returnTo;
      }

      // Only existing users will have a cookie!
      const loggedInBefore = !!Cookies.get(CustomCookies.HasLoggedInBefore);
      const path = loggedInBefore ? "/api/auth/login" : "/api/auth/signup";
      window.location.assign(
        `${path}?returnTo=${encodeURIComponent(returnToPath)}`
      );
    }, [user, error, isLoading, returnTo]);

    if (error) return <></>;
    if (user) return <Component user={user} {...(props as any)} />;
    return <></>;
  };
};

const withPageAuthRequired = (
  Component: ComponentType<any>,
  options: WithPageAuthRequiredOptions = {
    isAdvisorOnly: false,
    isOnboardingRequired: true,
    isPoF: false,
  }
) => {
  return withPageAuthRequiredAuth0(function WithPageAuthRequired(
    props: any
  ): JSX.Element {
    const { isOnboardingRequired, isAdvisorOnly, isPoF: isPoFPage } = options;

    const { isAdvisorPortal } = useIsAdvisor();
    const router = useRouter();
    const { isOnboardingCompleted, setIsOnboardingCompleted } = useStore();

    // reason - correct state based on result of async api request
    const handleQueryClientSuccess = useCallback(
      (client: Forme.Client) => {
        if (!isAdvisorPortal) {
          if (!client.onboardingCompleted && isOnboardingRequired) {
            router.push(ClientRoutes.Onboarding);
          }
          if (client.onboardingCompleted !== isOnboardingCompleted) {
            setIsOnboardingCompleted(client.onboardingCompleted);
          }
        }
      },
      [
        isAdvisorPortal,
        isOnboardingRequired,
        isOnboardingCompleted,
        router,
        setIsOnboardingCompleted,
      ]
    );
    const { isLoading } = useMeClient({
      onSuccess: handleQueryClientSuccess,
      refetchOnWindowFocus: false,
    });
    const { isPof: isPoFUser } = usePoFExperience();

    if (!Cookies.get(CustomCookies.HasLoggedInBefore)) {
      Cookies.set(CustomCookies.HasLoggedInBefore, "true", { expires: 365 });
    }

    if (!isAdvisorPortal && isAdvisorOnly) {
      return <div />;
    }

    if (isPoFPage && !isPoFUser) {
      return <div />;
    }

    if (
      isLoading ||
      (!isAdvisorPortal &&
        !isAdvisorOnly &&
        !isOnboardingCompleted &&
        isOnboardingRequired)
    ) {
      return <OnboardingRedirectScreen />;
    }

    return <Component {...(props as any)} />;
  });
};
export { withPageAuthRequired };
