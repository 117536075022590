const FORME_API_HOST =
  process.env.NODE_ENV === "development"
    ? "https://api.dev.formefinancial.com"
    : process.env.VERCEL_ENV !== "production" &&
      process.env.NEXT_PUBLIC_VERCEL_ENV !== "production"
    ? "https://api.qa.formefinancial.com"
    : "https://api.formefinancial.com";

const enum SOURCES {
  ORION = "ORION",
  MX = "MX",
}

const enum TYPES {
  ACCOUNT = "ACCOUNT",
  REGISTRATION = "REGISTRATION",
  CLIENT = "CLIENT",
}

const FORME_API_TOKEN_QUERY_KEY = "forme/accessToken";

export { FORME_API_HOST, SOURCES, TYPES, FORME_API_TOKEN_QUERY_KEY };
