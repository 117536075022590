import { FC } from "react";
import { CgSpinner } from "react-icons/cg";
import cx from "classnames";
import { themeTextColors } from "@common/constants";

interface LoadingIndicatorProps {
  isVisible: boolean;
  className?: string;
  color?: string;
}

const LoadingIndicator: FC<LoadingIndicatorProps> = ({
  isVisible,
  color,
  className,
}) => {
  return (
    <div
      className={cx(
        "flex h-full min-h-[inherit] w-full flex-1 flex-row items-center justify-center " +
          (isVisible ? "" : "hidden"),
        className
      )}
    >
      <CgSpinner
        size={24}
        color={color ?? themeTextColors.primary}
        className="animate-spin"
      />
    </div>
  );
};

export default LoadingIndicator;
