import { useMemo } from "react";
import { useAccounts } from "./queries";
import { SOURCES, TYPES } from "@modules/forme/constants";
import { OrionAccount } from "@modules/forme/types";
import moment from "moment";
import {
  getAccountSummaryByCategory,
  getOrionRegistrationWithNestedAccounts,
} from "./util";
import { useFingerprintScores } from "@modules/fingerprint/queries";
import { useHousehold } from "@common/queries";

const useOrionRegistrations = () => {
  const { data: accounts } = useAccounts();
  return useMemo(() => {
    return getOrionRegistrationWithNestedAccounts(accounts);
  }, [accounts]);
};

const useOrionAccountsWithTaxModel = () => {
  const { data: accounts } = useAccounts();
  return useMemo(() => {
    return (accounts ?? []).filter(
      (account) =>
        account.FORME_SOURCE === SOURCES.ORION &&
        account.FORME_TYPE === TYPES.ACCOUNT &&
        (account as OrionAccount)?.MODEL_AGG_ID === 21
    );
  }, [accounts]) as OrionAccount[];
};

const useOrionAccounts: () => OrionAccount[] = () => {
  const { data: accounts } = useAccounts();
  return useMemo(() => {
    return (accounts ?? []).filter(
      (account) =>
        account.FORME_SOURCE === SOURCES.ORION &&
        account.FORME_TYPE === TYPES.ACCOUNT
    ) as OrionAccount[];
  }, [accounts]);
};

const useYTDMxAccounts = () => {
  const { data: accounts, isLoading } = useAccounts();
  const startOfYear = moment().startOf("year");

  const filteredAccounts = useMemo(() => {
    return (accounts ?? []).filter((account) => {
      return (
        account.FORME_SOURCE === SOURCES.MX &&
        account.FORME_TYPE === TYPES.ACCOUNT &&
        moment((account as OrionAccount).CREATED_DATE).isSameOrAfter(
          startOfYear
        )
      );
    });
  }, [accounts, startOfYear]);

  return { data: filteredAccounts, isLoading };
};

const useIsLocked360View = () => {
  const { data: accounts, isSuccess } = useAccounts();
  const { data: fingerprints, isSuccess: isSuccessFingerprints } =
    useFingerprintScores();
  const { data: household, refetch } = useHousehold();
  const isLockedNetWorth = useMemo(
    () => isSuccess && (accounts ?? []).length === 0,
    [isSuccess, accounts]
  );
  const isLockedFingerprints = useMemo(
    () => isSuccessFingerprints && (fingerprints ?? []).length === 0,
    [isSuccessFingerprints, fingerprints]
  );
  const isLocked360View = useMemo(
    () =>
      household?.isLockedFinancialPlan &&
      household?.isLockedInvestments &&
      isLockedNetWorth &&
      isLockedFingerprints,
    [
      isLockedFingerprints,
      isLockedNetWorth,
      household?.isLockedInvestments,
      household?.isLockedFinancialPlan,
    ]
  );
  return {
    isLockedNetWorth,
    isLocked360View,
    refetch,
  };
};

const useTotalNetWorth = (opts = {}) => {
  const {
    data: accounts,
    isLoading: isLoadingAccounts,
    refetch,
  } = useAccounts({}, opts);
  const accountSummaryByCategory = useMemo(
    () => getAccountSummaryByCategory(accounts ?? []),
    [accounts]
  );
  const totalNetWorth = useMemo(() => {
    const allAssets = accountSummaryByCategory["ALL ASSETS"] ?? { total: 0 };
    const debt = accountSummaryByCategory["DEBT"] ?? { total: 0 };
    return allAssets.total + debt.total;
  }, [accountSummaryByCategory]);

  const isLoadingNetWorth = useMemo(() => {
    return isLoadingAccounts;
  }, [isLoadingAccounts]);

  const totalAccounts = useMemo(() => (accounts ?? []).length, [accounts]);

  return { totalNetWorth, isLoadingNetWorth, refetch, totalAccounts };
};

export {
  useYTDMxAccounts,
  useOrionAccounts,
  useOrionRegistrations,
  useOrionAccountsWithTaxModel,
  useIsLocked360View,
  useTotalNetWorth,
};
