import Image from "next/legacy/image";
import { useCallback } from "react";
import { useHousehold } from "@common/queries";
import E from "@logos/e.svg";
import useStore from "@common/state";
import { getAdvisorImage } from "@modules/advisory-team/utils";

const SidebarAdvisorAccess = ({
  onClick: _onClick,
}: {
  onClick?: () => void;
}) => {
  const { isSidebarExpanded, isAdvisorChatOpen, setIsAdvisorChatOpen } =
    useStore();

  const onClick = useCallback(() => {
    _onClick && _onClick();
    setIsAdvisorChatOpen(!isAdvisorChatOpen);
  }, [_onClick, setIsAdvisorChatOpen, isAdvisorChatOpen]);
  const { data: household, isLoading: isLoadingHousehold } = useHousehold();

  if (isLoadingHousehold) {
    return null;
  }

  return (
    <div className="mb-10">
      <a
        onClick={onClick}
        className={
          "SidebarChat group flex h-11 flex-none cursor-pointer flex-row items-center justify-center rounded rounded-md border-white md:justify-start " +
          (isSidebarExpanded
            ? "sidebar-advisor-container-expanded"
            : "sidebar-advisor-container-collapsed") +
          " " +
          (isAdvisorChatOpen ? "SidebarChat--active" : "")
        }
      >
        {household?.advisor ? (
          <div className="relative left-0 h-6 w-6 flex-none rounded-full group-hover:shadow-lg">
            <Image
              src={getAdvisorImage(household?.advisor?.firstName)}
              alt="profile picture"
              width={1200}
              height={1200}
              className="rounded-full border shadow-sm"
            />
            <div className="z-2 absolute bottom-0 right-0 mb-0.5 h-1.5 w-1.5 rounded-full border border-white bg-[#0CB03A]" />
          </div>
        ) : (
          <div className="relative flex h-6 w-5 flex-none flex-row items-center justify-center shadow-lg group-hover:shadow-lg">
            <Image src={E} width={14} height={14} alt="profile picture" />
            <div className="z-2 absolute bottom-0 right-0 mb-0.5 h-1.5 w-1.5 rounded-full border border-white bg-[#0CB03A]" />
          </div>
        )}
        <div
          className={
            "ml-2 flex flex-none flex-col leading-4 " +
            (isSidebarExpanded
              ? "sidebar-advisor-details-expanded"
              : "sidebar-advisor-details-collapsed")
          }
        >
          <div className="text-label-base text-white">
            Chat with {household?.advisor ? "advisors" : "Earned"}
          </div>
        </div>
      </a>
    </div>
  );
};

export default SidebarAdvisorAccess;
