import { Button, ButtonAttributes, Typography } from "@earned/core";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, ReactNode } from "react";
import { HiX } from "react-icons/hi";
import cx from "classnames";

interface IDialog {
  isOpen: boolean;
  onClose: () => void;
  title?: ReactNode | string;
  subtitle?: ReactNode | string;
  headline?: ReactNode | string;
  classNames?: {
    [key in "Background" | "Panel" | "Title" | "Description"]?: string;
  };
  size?: "sm" | "lg";
  onSubmit?: () => void;
  onSubmitText?: string;
  onSubmitVariant?: ButtonAttributes["variant"];
  onCloseText?: string;
  isSubmitting?: boolean;
  hideX?: boolean;
  children: ReactNode;
  titleItem?: ReactNode;
}
const _Dialog = ({
  isOpen,
  onClose,
  title,
  subtitle,
  headline,
  children,
  classNames,
  onSubmit,
  onSubmitText,
  onSubmitVariant = "primary",
  onCloseText = "Cancel",
  isSubmitting,
  hideX = false,
  size = "sm",
  titleItem,
}: IDialog) => {
  const classNamesBySize = {
    sm: {
      Background: "" + classNames?.Background ?? "",
      Panel:
        "min-w-[375px] max-w-[475px] md:min-w-[475px] " + classNames?.Panel ??
        "",
      Title: "pt-9 px-10 pb-6 " + classNames?.Title ?? "",
      Description: "px-10 py-6 " + classNames?.Description ?? "",
      Footer: "px-10 pb-6",
    },
    lg: {
      Background: "" + classNames?.Background ?? "",
      Panel: "w-8/12 max-w-[1200px] " + classNames?.Panel ?? "",
      Title: "pt-9 px-10 pb-6 " + classNames?.Title ?? "",
      Description: "" + classNames?.Description ?? "",
      Footer: "px-10 pb-6",
    },
  };
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog open={isOpen} onClose={onClose} className="relative z-[80]">
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className={
              "fixed inset-0 bg-overlay " + classNamesBySize[size]["Background"]
            }
          />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={
                  "rounded-lg border border-primary bg-white shadow-md " +
                  classNamesBySize[size]["Panel"]
                }
              >
                {(title || headline || titleItem) && (
                  <Dialog.Title
                    className={
                      "flex flex-row items-start justify-between px-10 pb-6 pt-9 " +
                      classNamesBySize[size]["Title"]
                    }
                  >
                    {title ? (
                      <div className="flex flex-col gap-0">
                        <Typography
                          variant="body-lg"
                          weight="medium"
                          color="primary"
                        >
                          {title}
                        </Typography>
                        {subtitle && (
                          <Typography variant="body-sm" color="primary">
                            {subtitle}
                          </Typography>
                        )}
                      </div>
                    ) : (
                      <Typography
                        variant="title-xl"
                        color="primary"
                        weight="medium"
                      >
                        {headline}
                      </Typography>
                    )}
                    {titleItem}
                    {!hideX && (
                      <button onClick={onClose} className="ml-4">
                        <HiX className="flex-none" size={24} />
                      </button>
                    )}
                  </Dialog.Title>
                )}
                <Dialog.Description
                  as="div"
                  className={
                    "flex flex-col " + classNamesBySize[size]["Description"]
                  }
                >
                  {children}
                </Dialog.Description>
                {onSubmit && (
                  <div
                    className={cx(
                      classNamesBySize[size]["Footer"],
                      "flex flex-row justify-end gap-2"
                    )}
                  >
                    <Button onClick={onClose} variant="tertiary">
                      {onCloseText}
                    </Button>
                    <Button
                      onClick={onSubmit}
                      variant={onSubmitVariant}
                      disabled={isSubmitting}
                    >
                      {onSubmitText}
                    </Button>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default _Dialog;
