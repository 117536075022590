const sharedConfig = require("@earned/tailwind-config");

module.exports = {
  presets: [sharedConfig],
  extend: {
    backgroundImage: {
      'referral-mobile': "url('/images/referral-mobile.png')",
      'referral-desktop': "url('/images/referral-desktop.png')",
    }
  },
  content: [
    `src/**/*.{js,ts,jsx,tsx}`,
    './node_modules/@earned/core/**/*.{js,ts,jsx,tsx}',
    './node_modules/@earned/wizard/**/*.{js,ts,jsx,tsx}',
  ],
  theme: {
    // TODO: create a better utility for retrieving config values dynamically which retain typings:
    colors: {
      ...sharedConfig.theme.colors,
      'advisor-line-bg-hover': '#EFF4FE',
      'advisor-line-bg-active': '#D7DDE7',
    }
  },
  plugins: [
    require("tailwind-scrollbar"),
    require("tailwindcss-autofill"),
    require("tailwindcss-text-fill"),
    require("tailwindcss-shadow-fill"),
  ],
  variants: {
    scrollbar: ["rounded"],
    extend: {
      // Enable `autofill` variant for plugins you want
      borderColor: ["autofill"],
      shadowFill: ["autofill"],
      textFill: ["autofill"],
    },
  },
};
