import { useCallback, useEffect, useRef, useState } from "react";
import TopNavbar from "@common/components/TopNavbar";
import Script from "next/script";
import useStore from "@common/state";
import { HiOutlineMail } from "react-icons/hi";
import { FaSms } from "react-icons/fa";
import {
  useZendeskAccessToken,
  useHousehold,
  useMeClient,
} from "@common/queries";
import { withPageAuthRequired } from "@common/middleware";
import { useUser } from "@auth0/nextjs-auth0";
import { advisorMeetingSetup } from "@common/utils";
import { useBreakpoints, usePoFExperience, useRouter } from "@common/hooks";
import { ClientRoutes } from "@common/constants";
import { Button } from "@earned/core";
import { ADVISOR_SUPPORT_EMAIL } from "@modules/advisory-team/constants";

const IFRAME_CSS_SELECTOR = '[role="presentation"]';
const MESSAGING_WINDOW_SELECTOR = '[title="Messaging window"]';

const AdvisorChat = () => {
  const { user } = useUser();
  const { isMobile, isZendeskMobile } = useBreakpoints();
  const { data: household } = useHousehold();
  const { data: client } = useMeClient();
  const [isZendeskAuthComplete, setIsZendeskAuthComplete] =
    useState<boolean>(false);
  const router = useRouter();
  const { isSidebarExpanded, isAdvisorChatOpen, setIsAdvisorChatOpen } =
    useStore();

  useEffect(() => {
    if (router.pathname === ClientRoutes.Onboarding) {
      setIsAdvisorChatOpen(false);
    }
  }, [router.pathname, setIsAdvisorChatOpen]);

  const containerRef = useRef<HTMLDivElement>(null);
  const isChatReady = window.zE && isZendeskAuthComplete && isAdvisorChatOpen;

  if (containerRef.current) {
    containerRef.current.style.display = isChatReady ? "flex" : "none";
    containerRef.current.style.top = "0px";
    containerRef.current.style.left = isMobile
      ? "0px"
      : isSidebarExpanded
      ? "288px"
      : "96px";
    containerRef.current.style.width = isMobile
      ? "100%"
      : isSidebarExpanded
      ? "calc(100% - 288px)"
      : "calc(100% - 96px)";
  }

  const fixUnreadCountPosition = useCallback(() => {
    function fixPosition() {
      const unreadIframe = document.querySelectorAll<HTMLElement>(
        '[title$="messages"]'
      )[0];
      if (unreadIframe) {
        if (isMobile) {
          unreadIframe.style.display = "none";
        } else if (isSidebarExpanded) {
          unreadIframe.style.left = "190px";
          unreadIframe.style.display = "block";
        } else {
          unreadIframe.style.left = "46px";
          unreadIframe.style.display = "block";
        }
      }
    }
    const unreadIframe = document.querySelectorAll<HTMLElement>(
      '[title$="messages"]'
    )[0];
    if (!unreadIframe) {
      // super hack: wait for iframe to load initial
      setTimeout(() => {
        fixPosition();
      }, 700);
      fixPosition();
    } else {
      fixPosition();
    }
  }, [isSidebarExpanded, isMobile]);

  const { data } = useZendeskAccessToken();
  const zendeskAccessToken = data?.accessToken;

  const authenticateZendesk = useCallback(() => {
    if (!window.zE || !zendeskAccessToken) return;
    return new Promise((resolve) => {
      window.zE(
        "messenger",
        "loginUser",
        async (callback: (token: string) => void) => {
          if (zendeskAccessToken) {
            callback(zendeskAccessToken);
            setIsZendeskAuthComplete(true);
            // NOTE: there is no way to know if the callback auth failed or suceeded.
            // HACK: to prevent flicker of the chat before auth is actually completed.
            setTimeout(() => {
              resolve(true);
            }, 300);
          }
        }
      );
    });
  }, [zendeskAccessToken, setIsZendeskAuthComplete]);

  const fixIframeStyles = useCallback(() => {
    const zendeskRootWrapperDiv =
      document.querySelectorAll<HTMLElement>(IFRAME_CSS_SELECTOR)[0];
    if (zendeskRootWrapperDiv) {
      zendeskRootWrapperDiv.style.position = isChatReady ? "relative" : "none";
      zendeskRootWrapperDiv.style.display = isChatReady ? "" : "none";
      zendeskRootWrapperDiv.style.height = isChatReady
        ? isMobile
          ? "calc(100% - 72px) !important"
          : "100vh !important"
        : "0";
      zendeskRootWrapperDiv.style.left = isMobile
        ? "0"
        : isSidebarExpanded
        ? "288px !important"
        : "96px !important";
      zendeskRootWrapperDiv.style.width = isMobile
        ? "100%"
        : isSidebarExpanded
        ? "calc(100% - 288px) !important"
        : "calc(100% - 96px) !important";
      zendeskRootWrapperDiv.style.maxWidth = isMobile
        ? "100%"
        : isSidebarExpanded
        ? "calc(100% - 288px) !important"
        : "calc(100% - 96px) !important";
    }

    const zendeskMessengerIframe = document.querySelectorAll<HTMLElement>(
      MESSAGING_WINDOW_SELECTOR
    )[0];
    if (zendeskMessengerIframe) {
      zendeskMessengerIframe.className = "!z-40";
      zendeskMessengerIframe.style.position = isChatReady ? "relative" : "none";
      zendeskMessengerIframe.style.display = isChatReady ? "" : "none";
      zendeskRootWrapperDiv.style.height = isChatReady
        ? isZendeskMobile
          ? "calc(100% - 62px)"
          : isMobile
          ? "calc(100% - 72px)"
          : "100vh"
        : "0";
      zendeskRootWrapperDiv.style.top = isZendeskMobile
        ? "62px"
        : isMobile
        ? "72px"
        : "0px";
      zendeskMessengerIframe.style.left = isMobile
        ? "0"
        : isSidebarExpanded
        ? "288px"
        : "96px";
      zendeskMessengerIframe.style.width = isMobile
        ? "100%"
        : isSidebarExpanded
        ? "calc(100% - 288px)"
        : "calc(100% - 96px)";
      zendeskMessengerIframe.style.maxWidth = isMobile
        ? "100%"
        : isSidebarExpanded
        ? "calc(100% - 288px)"
        : "calc(100% - 96px)";
    }
  }, [isChatReady, isMobile, isSidebarExpanded, isZendeskMobile]);

  useEffect(() => {
    async function showHideMessenger() {
      if (isChatReady) {
        window.zE("messenger", "open");
      } else if (window.zE) {
        window.zE("messenger", "close");
      }

      const zendeskRootWrapperDiv =
        document.querySelectorAll<HTMLElement>(IFRAME_CSS_SELECTOR)[0];
      if (zendeskRootWrapperDiv) {
        zendeskRootWrapperDiv.style.display = "none";
      }
      // Zendesk is running their own Javascript that we wait to override theirs.
      setTimeout(() => {
        fixUnreadCountPosition();
        fixIframeStyles();
      }, 100);
    }

    showHideMessenger();
  }, [
    isAdvisorChatOpen,
    isZendeskMobile,
    isSidebarExpanded,
    isZendeskAuthComplete,
    isChatReady,
    fixUnreadCountPosition,
    authenticateZendesk,
    isMobile,
    fixIframeStyles,
  ]);

  useEffect(() => {
    if (!isZendeskAuthComplete && zendeskAccessToken) {
      authenticateZendesk();
    }
  }, [authenticateZendesk, isZendeskAuthComplete, zendeskAccessToken]);

  const onClickScheduleCall = useCallback(() => {
    const meetingProps = advisorMeetingSetup(
      household?.advisor,
      user as Forme.Claims,
      client
    );
    window.Calendly.initPopupWidget(meetingProps);
  }, [user, household, client]);

  const { isPof } = usePoFExperience();

  return (
    <>
      {zendeskAccessToken && (
        <Script
          id="ze-snippet"
          src="https://static.zdassets.com/ekr/snippet.js?key=7b840e58-3c64-4783-8f7c-0fdd90e36fd9"
          onLoad={() => {
            if (window.zE) {
              window.zE("messenger", "close");
            }
            authenticateZendesk();
          }}
          strategy="lazyOnload"
        />
      )}
      <Script
        src="https://assets.calendly.com/assets/external/widget.js"
        type="text/javascript"
      />
      <div
        className="absolute flex hidden h-full flex-1 flex-col transition-all duration-300 ease-in-out"
        ref={containerRef}
      >
        <TopNavbar
          className="!z-[60]"
          title={
            isMobile
              ? "Chat"
              : !household?.advisorId
              ? "Chat with Earned"
              : "Chat with advisors"
          }
        >
          <div className="ml-2 flex flex-row items-center justify-end gap-4 lg:gap-8">
            <a href={`mailto:${ADVISOR_SUPPORT_EMAIL}`}>
              <HiOutlineMail color="inherit" size={24} />
            </a>
            {!isPof && (
              <a href="sms:+15104013515">
                <FaSms size={24} color="inherit" />
              </a>
            )}
            <Button variant="primary" size="sm" onClick={onClickScheduleCall}>
              Schedule Advisor Call
            </Button>
          </div>
        </TopNavbar>
        <div className="flex flex-1 bg-white"></div>
      </div>
    </>
  );
};

export default withPageAuthRequired(AdvisorChat, {
  isOnboardingRequired: false,
});
