import { AdvisorRoutes } from "@common/constants";
import { FC } from "react";
import { HiOutlineHome, HiOutlineCog, HiOutlineLogout } from "react-icons/hi";
import SidebarLink from "../Sidebar/SidebarLink";
import { useLogout } from "@common/hooks";

interface MobileAdvisorLinksProps {
  onClick: () => void;
}
const MobileAdvisorLinks: FC<MobileAdvisorLinksProps> = ({ onClick }) => {
  const { setShowLogoutDialog } = useLogout();
  return (
    <div className="grid gap-y-3">
      <span className="sidebar-section-text-expanded">Wealth Advisor</span>
      <SidebarLink
        href={AdvisorRoutes.Households}
        icon={<HiOutlineHome />}
        text="Households"
        onClickLink={onClick}
      />
      <SidebarLink
        href={AdvisorRoutes.Settings}
        icon={<HiOutlineCog />}
        text="Settings"
        onClickLink={onClick}
      />
      <SidebarLink
        text="Log Out"
        icon={<HiOutlineLogout />}
        href=""
        onClickLink={() => setShowLogoutDialog(true)}
      />
    </div>
  );
};

export default MobileAdvisorLinks;
